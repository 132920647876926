import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LoginForm from "../components/loginPage/LoginForm";
import Advantages from "../components/advantages/Advantages";
import About from "../components/about/About";
import Servis from "../components/servisy/Servis";
import Team from "../components/team/Team";
import Coments from "../components/coments/Coments";
import Title from "../components/title/Title";
import { useSelector } from "react-redux";
import Responsive from "../components/title/Slider";

const LoginPage = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  useEffect(() => {
    const handleScroll = () => {
      const component = document.querySelector(".fill");
      if (!component) return; // Проверяем, что компонент существует

      const componentPosition = component.getBoundingClientRect().top;
      const triggerPosition = window.innerHeight / 2;

      if (componentPosition < triggerPosition) {
        component.classList.add("animate");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Очищаем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <>
      <Header />
      <Title />
      <div>{isAuthorized ? null : <LoginForm />}</div>
      {/* <div className="pt-5">
        <Responsive />
      </div> */}
      <About />
      <Servis />
      <Advantages />
      <Team />
      <Coments />
      <Footer />
    </>
  );
};

export default LoginPage;
