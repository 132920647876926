import React from "react";
import HeaderForOzer from "./HeaderForOzer";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    y: 150,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const Blog = () => {
  return (
    <>
      <HeaderForOzer title="КОНТАКТЫ" subTitle="Контакты" />
      <div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          className="container pt-5 pb-5 mb-3"
        >
          <motion.div
            variants={textAnimation}
            custom={1}
            className="custom-shadow mt-5 mb-5 d-flex flex-column flex-lg-row justify-content-between align-items-center"
          >
            <motion.div
              variants={textAnimation}
              custom={2}
              className="custom-width-login"
            >
              <ul className="list-type d-flex flex-column gap-5 p-5 mt-5 mb-5">
                <li className="d-flex justify-content-center align-items-center me-5 pe-3 pe-lg-4">
                  <svg
                    enable-background="new 0 0 64 64"
                    width="100px"
                    height="100px"
                    fill="#f26947"
                    stroke="white"
                    version="1.1"
                    viewBox="0 0 64 64"
                    space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Glyph_copy_2">
                      <path d="M42.138,23.162c0-5.566-4.548-10.094-10.138-10.094s-10.138,4.528-10.138,10.094S26.41,33.256,32,33.256   S42.138,28.728,42.138,23.162z" />
                      <path d="M31.995,63.996l4.109-5.375c4.289-5.678,18.282-25.024,18.282-35.601C54.387,9.253,45.391,0.004,32,0.004   S9.613,9.253,9.613,23.021c0,11.39,16.432,33.166,18.301,35.605L31.995,63.996z M17.862,23.162c0-7.771,6.342-14.094,14.138-14.094   s14.138,6.323,14.138,14.094S39.796,37.256,32,37.256S17.862,30.934,17.862,23.162z" />
                    </g>
                  </svg>
                  <span className="fs-4 font-bold">Алматы</span>
                </li>
                <li className="d-flex flex-column align-items-center">
                  <span className="custom-text-color fs-6 font-bold">
                    ПОДДЕРЖКА
                  </span>
                  <a
                    href="tel:+77079245615"
                    className="text-decoration fs-4 text-black font-bold d-flex align-items-center gap-2"
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="30px"
                      height="30px"
                      fill="#f26947"
                      viewBox="0 0 477.156 477.156"
                      space="preserve"
                    >
                      <g>
                        <path
                          d="M475.009,380.316l-2.375-7.156c-5.625-16.719-24.062-34.156-41-38.75l-62.688-17.125c-17-4.625-41.25,1.594-53.688,14.031
		l-22.688,22.688c-82.453-22.28-147.109-86.938-169.359-169.375L145.9,161.94c12.438-12.438,18.656-36.656,14.031-53.656
		l-17.094-62.719c-4.625-16.969-22.094-35.406-38.781-40.969L96.9,2.19c-16.719-5.563-40.563,0.063-53,12.5L9.962,48.659
		C3.899,54.69,0.024,71.94,0.024,72.003c-1.187,107.75,41.063,211.562,117.281,287.781
		c76.031,76.031,179.454,118.219,286.891,117.313c0.562,0,18.312-3.813,24.375-9.845l33.938-33.938
		C474.946,420.878,480.571,397.035,475.009,380.316z"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    +7 707 924 56 15
                  </a>
                </li>
                <li className="d-flex flex-column align-items-center">
                  <span className="custom-text-color fs-6 font-bold">
                    ОТПРАВИТЬ ПИСЬМО
                  </span>
                  <a
                    href="mailto:info@hemo-ai.kz"
                    className="fs-4 text-black font-bold d-flex align-items-center gap-2"
                  >
                    <svg
                      version="1.1"
                      width="30px"
                      height="30px"
                      fill="#f26947"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 64 64"
                      space="preserve"
                    >
                      <path
                        d="M32,60C16.5,60,4,47.5,4,32S16.5,4,32,4c15.9,0,25,8.7,25,24v1c0.2,3.4-0.6,6.8-2.3,9.7c-1.8,3.1-5.1,5.1-8.7,5
	c-4,0-8.6-2.3-8.6-8.8v-0.1L38,22.9c-0.5-0.1-1-0.2-1.5-0.3c-1.4-0.2-2.7-0.3-4.1-0.3c-2.5-0.1-5,0.8-6.7,2.7
	c-1.8,2.1-2.8,4.8-2.6,7.6c-0.1,2.2,0.5,4.3,1.7,6.1c1.1,1.3,2.7,1.9,4.3,1.8c1.2,0,2.4-0.3,3.4-0.9c0.9-0.6,2.2-0.4,2.8,0.6
	s0.4,2.2-0.6,2.8C33,44,31,44.6,29.1,44.5c-2.8,0.1-5.6-1.1-7.4-3.3c-1.8-2.1-2.6-5-2.6-8.7c-0.2-3.8,1.1-7.5,3.7-10.3
	c2.5-2.7,6-4.1,9.7-4c1.6,0,3.1,0.1,4.7,0.4c1.2,0.2,2.4,0.4,3.5,0.7l1.6,0.4L41.4,35c0,4.1,2.9,4.7,4.6,4.7c2.1,0,4.1-1.1,5.2-2.9
	c1.4-2.4,2-5.1,1.8-7.8v-1c0-12.9-7.5-20-21-20C18.7,8,8,18.7,8,32c0,13.3,10.7,24,24,24c6.2,0,12.1-2.4,16.6-6.7
	c0.8-0.8,2.1-0.7,2.8,0.1c0.8,0.8,0.7,2.1-0.1,2.8l0,0C46.2,57.2,39.2,60,32,60z"
                      />
                    </svg>
                    info@hemo-ai.kz
                  </a>
                </li>
              </ul>
            </motion.div>
            <motion.div
              variants={textAnimation}
              custom={1}
              className="bg-dark p-5 custom-width-login d-flex flex-column align-items-center align-items-lg-start gap-4"
            >
              <motion.h2
                variants={textAnimation}
                custom={2}
                className="fs-4 font-bold text-light"
              >
                Для вопросов
              </motion.h2>
              <motion.input
                variants={textAnimation}
                custom={2}
                id="email"
                type="email"
                name="email"
                placeholder="Имя"
                className={`text-light text-3 ps-2 custom-border-color-grey-1 h-auto py-2 w-100`}
              />
              <motion.input
                variants={textAnimation}
                custom={2}
                id="email"
                type="email"
                name="email"
                placeholder="Почта"
                className={`text-light text-3 ps-2 custom-border-color-grey-1 h-auto py-2 w-100`}
              />
              <motion.textarea
                variants={textAnimation}
                custom={2}
                className="bg-dark w-100 text-light resize custom-media p-2 "
                placeholder="Сообщение"
              ></motion.textarea>
              <motion.div
                variants={textAnimation}
                custom={2}
                className="position-relative mb-3"
              >
                <button
                  className="text-decoration-none animate-text z-1 fw-bold fs-10 position-absolute p-2 text-light border-none me-4 d-flex justify-content-center custom-positio bg-link"
                  type="submit"
                >
                  Отправить
                </button>
                <span className="d-block custom-io animate-text z-0"></span>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
        <iframe
          className="mb-3"
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d92997.13593281325!2d76.91833986136781!3d43.24805797466091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2skz!4v1710029735506!5m2!1sru!2skz"
          style={{ border: "0", height: "450px", width: "100%" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Blog;
