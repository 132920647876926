import { Box, List, ListItem } from "@chakra-ui/react";
export const IndividualCategories = ({ category, onSetCategory }) => {
  const symptoms = [
    { label: "Метаболические Симптомы", value: "metob" },
    { label: "Дерматологические Симптомы", value: "derm" },
    { label: "Неврологические и Психические Симптомы", value: "neuro" },
    { label: "Сердечно-сосудистые Симптомы", value: "cardio" },
    { label: "Гастроинтестинальные Симптомы", value: "gastro" },
  ];
  return (
    <Box>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          fontWeight: "600",
          minWidth: { base: 100, xl: 480 },
        }}
      >
        {symptoms.map(({ label, value }, idx) => (
          <ListItem
            onClick={() => onSetCategory(idx)}
            sx={{
              color: category === value ? "brand.accent" : "gray",
              cursor: "pointer",
              transition: "opacity 0.25s ease-in-out",
              fontSize: { base: 18, xl: 19 },

              _hover: {
                opacity: 0.9,
              },
            }}
          >
            {label}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
