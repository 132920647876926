import React from "react";

const Terms = () => {
  return (
    <div className="container">
      <h4 className="font-bold fs-6 mb-3">
        Условия Использования сервиса Hemo-AI от ИП LoanaAI
      </h4>
      <p className="fs-10 text-secondary lh-lg">
        Используя приложение, вы автоматически соглашаетесь с этими условиями,
        поэтому вам следует внимательно их прочитать перед использованием
        приложения. Вам не разрешается копировать или модифицировать приложение,
        любую часть приложения или наши товарные знаки каким-либо образом. Вам
        не разрешается пытаться извлечь исходный код приложения, а также не
        следует пытаться перевести приложение на другие языки или создавать
        производные версии. Само приложение, а также все товарные знаки,
        авторские права, права на базы данных и другие права интеллектуальной
        собственности, связанные с ним, по-прежнему принадлежат Hemo-AI.
      </p>
      <p className="fs-10 text-secondary lh-lg">
      Вся информация, предоставляемая Искусственным Интеллектом предназначена 
      только в образовательных целях и не должна использоваться в качестве замены 
      профессиональной медицинской консультации, диагностики или лечения.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Hemo-AI стремится обеспечить максимальную полезность и эффективность
        приложения. По этой причине мы оставляем за собой право вносить
        изменения в приложение или взимать плату за его услуги в любое время и
        по любой причине. Мы никогда не будем взимать с вас плату за приложение
        или его услуги, не уведомив вас заранее о том, за что именно вы платите.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Приложение Hemo-AI хранит и обрабатывает личные данные, которые вы
        предоставили нам, для предоставления нашего Сервиса. Вы несете
        ответственность за безопасность вашего телефона и доступа к приложению.
        Поэтому мы рекомендуем вам не делать jailbreak или root вашего телефона,
        что является процессом удаления программных ограничений и ограничений,
        налагаемых официальной операционной системой вашего устройства. Это
        может сделать ваш телефон уязвимым для вредоносных
        программ/вирусов/злонамеренных программ, подорвать функции безопасности
        вашего телефона и может привести к тому, что приложение Hemo-AI не будет
        работать должным образом или вообще не будет работать. Приложение
        использует услуги третьих сторон, которые объявляют свои Условия
        Использования.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Что касается ответственности Hemo-AI за ваше использование приложения,
        когда вы используете приложение, важно помнить, что, хотя мы стремимся
        обеспечить его актуальность и корректность в любое время, мы полагаемся
        на третьих сторон для предоставления нам информации, чтобы мы могли
        сделать ее доступной для вас. Hemo-AI не принимает на себя никакой
        ответственности за любые прямые или косвенные потери, которые вы
        испытываете в результате полной зависимости от этой функциональности
        приложения.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Изменения в Этих Условиях и Положениях
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Мы можем время от времени обновлять наши Условия и Положения. Поэтому
        вам рекомендуется периодически просматривать эту страницу на предмет
        изменений. Мы уведомим вас о любых изменениях, опубликовав новые Условия
        и Положения на этой странице.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Эти условия и положения вступают в силу с 1 апреля 2024 года.
      </p>
      <p className="fs-10 text-secondary lh-lg">Свяжитесь с Нами</p>
      <p className="fs-10 text-secondary lh-lg">
        Если у вас есть какие-либо вопросы или предложения относительно наших
        Условий и Положений, не стесняйтесь обращаться к нам по адресу
        <br />
        <a
          className="text-decoration custom-text-color font-bold"
          href="mailto:info@hemo-ai.kz"
        >
          admin@hemo-ai.kz.
        </a>
      </p>
    </div>
  );
};

export default Terms;
