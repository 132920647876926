import React from "react";
import insta from "./icons/instagram.svg";
import tel from "./icons/phone.svg";
import mail from "./icons/mail.svg";
import gps from "./icons/gps.svg";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "./icons/HEMO-AI.png";
import { useSelector } from "react-redux";

const Footer = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  return (
    <footer className="bg-dark">
      <div className="container">
        <div className="border-bottom border-secondary pb-2 pt-5 d-flex justify-content-between">
          {isAuthorized ? (
            <Link to="/hemo">
              <img className="custom-img" src={logo} alt="Hemo-ai" />
            </Link>
          ) : (
            <Link>
              <img className="custom-img" src={logo} alt="Hemo-ai" />
            </Link>
          )}
          <div className="position-relative">
            <Link
              className="text-decoration-none fw-bold fs-10 position-absolute p-2 text-light  me-4 d-flex justify-content-center custom-positio bg-link"
              to="/contacts"
            >
              Свяжитесь с нами
            </Link>
            <span className="d-block custom-io bg-secondary"></span>
          </div>
        </div>
        <div className="border-bottom border-secondary pt-5 pb-xl-4 d-flex flex-column flex-xl-row justify-content-xl-around align-items-center align-items-xl-start">
          <ul className="text-light list-type d-flex flex-column custom-gap me-3 mt-2 mb-5 md-xl-0">
            <li className="text-light">
              <a
                href="tel:+77079245615"
                className="text-secondary d-flex align-items-center gap-2 text-decoration-none font-weight-semibold custom-fs"
              >
                <img src={tel} alt="Instagram" />
                +7 707 924 56 15
              </a>
            </li>
            <li>
              <a
                href="mailto:info@hemo-ai.kz"
                className="d-flex align-items-center gap-2 text-secondary text-decoration-none font-weight-semibold custom-fs"
              >
                <img src={mail} alt="Instagram" />
                info@hemo-ai.kz
              </a>
            </li>
            <li>
              <a
                href="http://www.instagram.com/hemoai"
                target="_blank"
                className="d-flex align-items-center gap-2 text-secondary text-decoration-none font-weight-semibold custom-fs"
                title="Instagram"
                rel="noreferrer"
              >
                <img src={insta} alt="Instagram" />
                @hemoai
              </a>
            </li>
            <li className="d-flex align-items-center gap-2 text-secondary text-decoration-none font-weight-semibold custom-fs">
              <img src={gps} alt="Instagram" />
              Алматы
            </li>
          </ul>
          <div className="iframe-wrapper pb-5 pb-xl-1">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d92997.13593281325!2d76.91833986136781!3d43.24805797466091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2skz!4v1710029735506!5m2!1sru!2skz"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-100 h-100"
            ></iframe>
          </div>
        </div>
        <div className="text-secondary custom-fs d-flex justify-content-center p-4">
          Hemo-AI. © 2024. All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
