import React, { useEffect } from "react";
import Policy from "../components/policy/Policy";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const PolisuPage = () => {
  useEffect(() => {
    const element = document.documentElement;
    element.scrollIntoView({ behavior: "instant", block: "start" });
  }, []);
  return (
    <>
      <Header />
      <Policy />
      <Footer />
    </>
  );
};

export default PolisuPage;
