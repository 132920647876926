import React, { useState } from "react";
import "./PasswordRecover.css";
import Tau from "../tau/Tau";

const PasswordRecover = () => {
  const [email, setEmail] = useState("");

  const [errors, setErrors] = useState();

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setErrors("");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!email || email.length <= 0) {
      setErrors(`Пожалуйста введите E-mail`);
    } else {
      console.log("Form submitted:", email);
    }
  };

  return (
    <div className="bg-dark pb-xl-5">
      <Tau />
      <div className="container text-light">
        <div className="">
          <div className="mb-xl-5">
            <form
              className="d-flex custom-width-from-input  pt-xl-5 flex-column gap-1 "
              onSubmit={handleFormSubmit}
            >
              <h2 className="fs-3 mt-5 pt-5 animate-text">
                Восстановление пароля
              </h2>
              <label
                htmlFor="email"
                className=""
                style={{ overflowWrap: "break-word" }}
              ></label>
              <div className="text-light mb-4 pt-1">
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Введите ваш E-mail"
                  value={email}
                  onChange={handleInputChange}
                  className={` ${
                    errors ? "is-invalid" : ""
                  }  text-light text-3 ps-2 custom-border-color-grey-1 h-auto py-2 w-100`}
                />
                {errors && <div className="invalid-feedback">{errors}</div>}
              </div>
              <div className="position-relative">
                <button
                  type="submit"
                  className="border-none animate-text fw-bold z-1 fs-10 position-absolute p-2 text-light  me-4 d-flex justify-content-center custom-positio bg-link"
                  to="/contacts"
                >
                  Восстановить
                </button>
                <span className="d-block custom-io animate-text z-0 bg-secondary"></span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecover;
