import React, { useState } from "react";
import "./Registration";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utiles/baseUrl";
import Tau from "../tau/Tau";

const inputType = [
  {
    id: 1,
    type: "email",
    name: "email",
    description: "Email",
    gap: "gap-3",
    placeholder: "Введите ваш E-mail",
  },
  {
    id: 2,
    type: "password",
    name: "password",
    description: "Password",
    placeholder: "Придумайте пароль",
    gap: "",
  },
];

const RegistrationPage = () => {
  const [logined, setLogined] = useState(false);
  const [logError, setLogError] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    inputType.forEach((input) => {
      if (!formData[input.name]) {
        newErrors[
          input.name
        ] = `Пожалуйста введите ${input.description.toLowerCase()}`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors(newErrors);
      fetch(`${baseUrl}user/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        // .catch()
        .then((data) => {
          if (data.error) {
            setLogError(true);
            setTimeout(() => {
              setLogError(false);
            }, 5000);
          } else {
            setLogined(true);
            setTimeout(() => {
              setLogined(false);
            }, 5000);
          }
        });
    }
  };

  return (
    <div className="bg-dark pb-xl-5">
      <Tau />
      <div className="container text-light mb-xl-4">
        <div className="">
          <div className="mb-xl-5">
            <form
              className="d-flex custom-width-from-input  pt-xl-5 flex-column gap-1"
              onSubmit={handleFormSubmit}
            >
              <h2 className="fs-3 mt-5 pt-5 animate-text">Регистрация</h2>
              <div className="">
                {logined ? (
                  <div className="text-center fs-5 text-warning">
                    Поздравляем, Вы зарегистрированы!
                  </div>
                ) : null}
                {logError ? (
                  <div className="text-center fs-5 text-danger">
                    Такой аккаунт уже существует
                  </div>
                ) : null}
                {inputType.map((input) => (
                  <div key={input.id} className="text-light mb-4 pt-1">
                    <label
                      htmlFor={input.name}
                      className=" text-wrap text-break"
                      style={{ overflowWrap: "break-word" }}
                    ></label>
                    <input
                      type={input.type}
                      name={input.name}
                      placeholder={input.placeholder}
                      value={formData[input.name]}
                      onChange={handleInputChange}
                      className={`${
                        errors[input.name] ? "is-invalid" : ""
                      } text-light text-3 ps-2 custom-border-color-grey-1 h-auto py-2 w-100`}
                      required
                    />
                    {errors[input.name] && (
                      <div className="invalid-feedback">
                        {errors[input.name]}
                      </div>
                    )}
                  </div>
                ))}
                <div className="position-relative pt-xl-3">
                  <button
                    className="border-none fw-bold animate-text z-1 fs-10 position-absolute p-2 text-light  me-4 d-flex justify-content-center custom-positio bg-link mt-xl-3"
                    type="submit"
                  >
                    Зарегистрироваться
                  </button>
                  <span className="d-block custom-io bg-secondary animate-text z-0"></span>
                </div>
              </div>
            </form>
            <div className="mt-3 d-flex align-items-baseline pb-4 pb-xl-1">
              <span className="custom-width text-secondary animate-text">
                Есть аккаунт?
              </span>
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="custom-color border-none ms-3 bacground-transperent animate-text"
              >
                Войти
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
