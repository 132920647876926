import React from "react";
import { motion } from "framer-motion";
// import Responsive from "./Slider";

const textAnimation = {
  hidden: {
    y: 200,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const avatarAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.1,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const Title = () => {
  return (
    <div className="hero">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="pt-5 bg-title z-0 position-relative"
      >
        <div className="container mt-4 z-0 wrapper">
          <motion.h1
            custom={1}
            variants={textAnimation}
            class="gradient-text pb-2 mb-3 mb-xl-5 lh-1 z-3 title__text"
          >
            ИНДИВИДУАЛЬНЫЕ
            <br />
            РЕКОМЕНДАЦИИ
            <br />
            ПО ВОПРОСАМ ВАШЕГО ЗДОРОВЬЯ
          </motion.h1>
          <motion.div
            variants={avatarAnimation}
            custom={5}
            className=" d-none d-lg-block position-absolute custom-bg-title"
          ></motion.div>
          <div className="d-flex flex-column w-100">
            <motion.p
              custom={2}
              variants={textAnimation}
              className="fs-4 font-bold lh-sm custom-width-login subtitle__text"
            >
              Твой персональный советник
            </motion.p>
            <motion.span
              custom={3}
              variants={textAnimation}
              className="fs-10 text-secondary mb-5 custom-width-login"
            >
              Зарегистрируйтесь и получите рекомендации за 5 простых шага. Мы оценим ваши симптомы и анализы
              и дадим рекомендации для улучшения вашего здоровья и общего состояния.
            </motion.span>
          </div>
        </div>
        {/* <svg
          className="position-absolute positions-svg w-100"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 102"
          preserveAspectRatio="xMinYMin"
        >
          <polygon
            fill="#FFFFFF"
            points="1920,65 1700,35 1358,63 989,10 510,68 0,16 0,103 1920,103 "
          ></polygon>
        </svg> */}

       
      </motion.div>
    </div>
  );
};

export default Title;
