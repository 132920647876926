import React from "react";
import HeaderForOzer from "./HeaderForOzer";

const ConsultationWithANutritionist = () => {
  return (
    <>
      <HeaderForOzer
        title="ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ НУТРИЦИОЛОГА"
        subTitle="Получить консультацию нутрициолога"
      />
      <div className="container fs-10 text-secondary lh-lg mt-5 pt-5 mb-5 pb-5">
      Напишите нам по номеру +7 707 9245615 или на почту info@hemo-ai.kz.
      </div>
    </>
  );
};

export default ConsultationWithANutritionist;
