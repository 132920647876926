const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <svg
      className={className}
      style={{
        ...style,
        display: "block",
        top: "50%",
        right: "-10%",
        height: "50px",
        width: "50px",
      }}
      onClick={onClick}
      height="100px"
      viewBox="0 0 32 32"
      width="100px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="m0 0h32v32h-32z" />
        <path
          d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
          fill="#f37353"
          transform="matrix(-1 0 0 -1 32.04264 31.985282)"
        />
      </g>
    </svg>
  );
};

export default NextArrow;
