import React from "react";

const Tau = () => {
  return (
    <div className="reverse-img bg-dark position-relative">
      <svg
        className="position-absolute positions-svg w-100"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 102"
        preserveAspectRatio="xMinYMin"
      >
        <polygon
          fill="#FFFFFF"
          points="0,65 220,35 562,63 931,10 1410,68 1920,16 1920,103 0,103 "
        ></polygon>
      </svg>
    </div>
  );
};

export default Tau;
