import React from "react";
import Slider from "react-slick";
import cart from "./img/icon.svg";
import "./Title.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const initialTitle = [
  {
    img: cart,
    title: "Расшифровка анализов",
    button: "Посмотреть больше",
    path: "#servis",
  },
  {
    img: cart,
    title: "Рекомендации по витаминам и минералам",
    button: "Посмотреть больше",
    path: "#servis",
  },
  {
    img: cart,
    title: "Выгодные пакеты для всей семьи",
    button: "Посмотреть больше",
    path: "#servis",
  },
];

function Responsive() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const textAnimation = {
    hidden: {
      y: 200,
      opacity: 0,
    },
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: custom * 0.2,
        duration: 1, // Длительность анимации (в секундах)
        ease: "easeInOut",
      },
    }),
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="pb-5"
    >
      <motion.div custom={4} className="" variants={textAnimation}>
        <Slider {...settings} className="slider-with-padding">
          {initialTitle.map((item, index) => {
            return (
              <Link
                to={item.path}
                key={index}
                className="d-flex text-decoration flex-column align-items-center bg-light gap-3 custom-shadow p-2 pt-5 custom-width-title"
              >
                <Link to={item.path} className="pt-2">
                  <img src={item.img} alt="" />
                </Link>
                <h3 className="fs-6 font-bold text-black text-center">
                  {item.title}
                </h3>
                <p className="custom-text-color fs-10">
                  {item.button}
                  <svg
                    height="30px"
                    viewBox="0 0 32 32"
                    width="30px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <path d="m0 0h32v32h-32z" />
                      <path
                        d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
                        fill="#f37353"
                        transform="matrix(-1 0 0 -1 32.04264 31.985282)"
                      />
                    </g>
                  </svg>
                </p>
              </Link>
            );
          })}
        </Slider>
      </motion.div>
    </motion.div>
  );
}

export default Responsive;
