import "aos/dist/aos.css";
import AOS from "aos";
import PublicPage from "./page/PublicPage";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login } from "./store/authSlice";
import { useNavigate } from "react-router-dom";

const App = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Check if user data is in local storage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      // Dispatch login action with stored user data
      dispatch(login(userData.email));
    }
    if (isAuthorized) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, dispatch]);

  return (
    <>
      <PublicPage />
    </>
  );
};

export default App;
