import {
  Box,
  Button,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { lighten } from "polished";
export const IndividualTest = ({
  category,
  onClick,
  onChangeResult,
  isLastTest,
  onFinish,
}) => {
  return (
    <Box sx={{ p: "20px" }}>
      <List sx={{ mb: "16px" }}>
        {questions[category]?.map(({ label, value }, idx) => (
          <ListItem sx={{ mb: "20px" }} key={label}>
            <Text sx={{ fontSize: { base: 16, lg: 18 }, fontWeight: 600 }}>
              {label}
            </Text>
            <RadioGroup
              onChange={(action) => {
                onChangeResult(value, action);
              }}
              defaultValue="no"
            >
              <Stack direction="column">
                <Radio
                  value="yes"
                  sx={{ borderColor: "black", fontSize: { base: 14, lg: 16 } }}
                >
                  Да
                </Radio>
                <Radio value="no" sx={{ borderColor: "black" }}>
                  Нет
                </Radio>
              </Stack>
            </RadioGroup>
          </ListItem>
        ))}
      </List>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        {isLastTest ? (
          <Button
            sx={{
              background: "brand.accent",
              color: "#fff",
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
            }}
            size="lg"
            onClick={onFinish}
          >
            Получить рекомендации
          </Button>
        ) : (
          <Button
            sx={{
              background: "brand.accent",
              color: "#fff",
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
            }}
            size="lg"
            onClick={onClick}
          >
            Дальше
          </Button>
        )}
      </Box>
    </Box>
  );
};

// Вопросы

const questions = {
  metob: [
    {
      label: "Чувствуете ли вы общую слабость?",
      value: [1, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Быстро ли вы утомляетесь?",
      value: [1, 1, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Замечали ли вы пониженную работоспособность или упадок сил?",
      value: [1, 1, 0], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Наблюдаете ли вы необъяснимое увеличение веса при сниженном аппетите?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Чувствуете ли вы постоянную жажду?",
      value: [0, 1, 0], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Часто ли у вас бывают позывы к мочеиспусканию?",
      value: [0, 1, 1], // [anemia, diabet, hypothyroidism]
    },

    {
      label:
        "Страдаете ли вы от жидкого стула, тошноты или рвоты периодически?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Чувствуете ли вы слабость, дрожь в руках или теле?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
  ],

  derm: [
    {
      label: "Замечали ли вы сухость кожи?",
      value: [1, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Наблюдаете ли вы бледность кожи или слизистых оболочек?",
      value: [1, 0, 0], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Появлялись ли у вас гнойники на коже?",
      value: [0, 1, 0], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Появляются ли у вас отеки, особенно на лице?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Наблюдаете ли вы редкие, жесткие или сухие волосы?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Выпадают ли у вас волосы?",
      value: [1, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Наблюдаете ли вы ломкость или истончение ногтей?",
      value: [1, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Замечали ли вы длительное заживление ран?",
      value: [0, 1, 0], // [anemia, diabet, hypothyroidism]
    },
  ],

  neuro: [
    {
      label: "Испытываете ли вы головокружение?",
      value: [1, 1, 0], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Слышите ли вы шум или звон в ушах? ",
      value: [1, 0, 0], // [anemia, diabet, hypothyroidism]
    },

    {
      label:
        "Страдаете ли вы бессонницей?",
      value: [0, 1, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Наблюдаете ли вы снижение памяти, забывчивость или отсутствие внимания?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Испытываете ли вы плаксивость, повышенную тревожность или депрессию?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },

    {
      label:
        "Чувствуете ли вы нервозность, чувство тревоги или повышенную возбудимость?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },

  ],

  cardio: [
    {
      label:
        "Чувствуете ли вы усиленное сердцебиение?",
      value: [1, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Наблюдаете ли вы повышение артериального давления или частоты пульса?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
    {
      label: "Замечали ли вы замедленный сердечный ритм?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },

  ],

  gastro: [
    {
      label: "Испытываете ли вы затруднённое дыхание?",
      value: [1, 0, 0], // [anemia, diabet, hypothyroidism]
    },
    {
      label:
        "Замечали ли вы склонность к запорам или неустойчивый стул?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },

    {
      label:
        "Для женщин: Испытываете ли вы обильные или болезненные месячные либо вторичную аменорею?",
      value: [0, 0, 1], // [anemia, diabet, hypothyroidism]
    },
  ],
};
