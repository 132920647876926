import React from "react";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import svg from "./img/top.svg";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    y: 150,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1,
      ease: "easeInOut",
    },
  }),
};

const initialComments = [
  {
    coment:
      "Долгое время я страдала от быстрой утомляемости, головокружений и сухости кожи. Сначала не придавала этому значения, но с каждым днем симптомы усиливались. С помощью анализов и консультаций я узнала, что у меня инсулинорезистентность, которая могла перерасти в диабет 2-го типа. Специалисты предложили персональный план питания и рекомендовали увеличить в рационе количество клетчатки и полезных жиров. Следуя вашим рекомендациям, я уже через несколько недель почувствовала значительное улучшение — исчезла постоянная усталость, нормализовался уровень сахара в крови, а кожа стала более увлажненной. Благодарю за помощь и внимание к деталям!",
    bgTop: svg,
    name: "Диана, 42 года",
    profession: "Болеет анемией",
  },
  {
    coment: "Я обратилась на сайт из-за постоянной усталости, выпадения волос и сухой кожи. Команда предложила сдать анализы на дому, и уже через несколько дней я получила результаты и подробные рекомендации. Выяснилось, что у меня анемия и дефицит витаминов группы В. Мне подобрали подходящие витамины и скорректировали мой рацион. Уже через месяц я почувствовала себя гораздо лучше — волосы перестали выпадать, кожа стала увлажненной, а энергия вернулась! Очень благодарна за помощь и внимание к деталям.",
    bgTop: svg,
    name: "Марина, 35 лет",
    profession: "Анемия и дефицит витаминов В",
  },
  {
    coment: "Меня беспокоили отечность лица, постоянная слабость и сухая кожа. Я уже не знала, что делать, пока не нашла ваш сайт. После сдачи анализов выяснилось, что у меня гипотериоз. И уровень гормонов в щитовых нежилях был ниже нормы. Ваши специалисты порекомендовали диету богато-нехладимыми нутриентами, а также добавки селена, витамином D и комплексом витаминов группы B для поддержания функции щитовидной железы. Через несколько недель лицо стало менее пухшим, появилась энергия и кожа перестала быть такой сухой. Спасибо за вашу поддержку и персональный подход.",
    bgTop: svg,
    name: "Айгуль, 38 лет",
    profession: "Болеет анемией",
  },
];

const Coments = () => {
  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <NextArrow className="m-5" />,
    prevArrow: <PrevArrow />,
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
      className="bg-custom-slider pb-5 pt-lg-5"
    >
      <div className="container pb-lg-5">
        <div className="text-center pt-5">
          <motion.span
            variants={textAnimation}
            custom={1}
            className="custom-letter custom-text-color font-bold"
          >
            ОТЗЫВЫ
          </motion.span>
          <motion.h2
            variants={textAnimation}
            custom={2}
            className="fs-3 font-bold p-3"
          >
            Что люди говорят о нас
          </motion.h2>
        </div>
        <motion.div
          variants={textAnimation}
          custom={3}
          className="border bg-light m-4"
        >
          <Slider {...settings}>
            {initialComments.map((item, index) => {
              return (
                <div
                  key={index}
                  className="pt-5 pb-5 d-flex flex-column align-items-center gap-4"
                  style={{ maxWidth: "700px", margin: "0 auto" }} // Adjust container width
                >
                  <img className="mt-4" src={item.bgTop} alt="" />
                  <p
                    className="lh-sm"
                    style={{
                      textAlign: "center", // Center-align the text
                      whiteSpace: "normal", // Ensure the text wraps properly
                      overflowWrap: "break-word", // Handle long words
                      wordBreak: "break-word", // Ensure long text breaks into new lines
                      maxWidth: "90%", // Ensure text doesn't exceed 90% of container width
                    }}
                  >
                    {item.coment}
                  </p>
                  <div className="text-center mb-5 d-flex flex-column">
                    <span className="fs-4 font-bold">{item.name}</span>
                    <span className="fs-10">{item.profession}</span>
                  </div>
                </div>
              );
            })}
          </Slider>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Coments;
