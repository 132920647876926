import React from "react";
import Header from "../components/header/Header";
import ResponsAi from "../components/responseAiPage/ResponsAi";
import Footer from "../components/footer/Footer";
import { useEffect } from "react";

const ResponseAiPage = () => {
  useEffect(() => {
    const element = document.documentElement;
    element.scrollIntoView({ behavior: "instant", block: "start" });
  }, []);
  return (
    <>
      <Header />
      <ResponsAi />
      <Footer />
    </>
  );
};

export default ResponseAiPage;
