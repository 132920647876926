import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Preparation from "../components/other/Preparation";
import Footer from "../components/footer/Footer";

const PreparationPage = () => {
  useEffect(() => {
    const element = document.documentElement;
    element.scrollIntoView({ behavior: "instant", block: "start" });
  }, []);
  return (
    <>
      <Header />
      <Preparation />
      <Footer />
    </>
  );
};

export default PreparationPage;
