import React from "react";
import HeaderForOzer from "./HeaderForOzer";

const Simptoms = () => {
  return (
    <>
      <HeaderForOzer title="СИМПТОМЫ" subTitle="Симптомы" />
      <div className="container mt-2">
        <div className="d-flex">
          <h2 className="fs-5 font-bold mb-5">
            Вам нужно сдать общий анализ крови (ОАК) если вы заметили у себя
            следующие симптомы:
          </h2>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between mb-5">
          <h3 className="fs-6 font-bold section-custom-width1">
            Характерные признаки при проблемах с щитовидной железой
          </h3>
          <div>
            <p className="custom-text-color fs-10">
              Дополнительно рекоммендовано:
            </p>
            <p className="custom-text-color fs-10">
              сдать УЗИ щитовидной железы и обязательно проконсультироваться с
              врачом.
            </p>
          </div>
          <ul className="fs-10 text-secondary section-custom-width1">
            <li>Общая слабость, сонливость, быстрая утомляемость</li>
            <li>Снижение памяти, забывчивость, отсутствие внимания</li>
            <li>Плаксивость, повышенная тревожность, депрессия</li>
            <li>Необъяснимое увеличение веса на фоне сниженного аппетита</li>
            <li>
              Появление отеков, особенно на лице, от незначительных до
              выраженных
            </li>
            <li>Охриплость или осиплость голоса, сухость кожи</li>
            <li>Повышенная ломкость волос и ногтей</li>
            <li>Мышечные судороги, боли в суставах</li>
            <li>
              Замедление пульса, повышение диастолического артериального
              давления
            </li>
            <li>Склонность к запорам или неустойчивый стул</li>
            <li>Нарушения менструального цикла, снижение полового влечения</li>
            <li>
              Нарушение эрекции, снижение полового влечения, бесплодие или
              выкидыши
            </li>
            <li>Повышенная потливость, влажная кожа</li>
            <li>
              Увеличение, выпячивание глаз, отечность и гиперпигментация век
            </li>
            <li>
              Повышение артериального давления, частоты пульса, сердцебиения
            </li>
            <li>Снижение массы тела при повышенном аппетите</li>
            <li>Жидкий стул, тошнота, рвота - периодически</li>
            <li>Быстрая утомляемость, слабость, дрожь в руках и теле</li>
            <li>Признаки остеопороза</li>
            <li>
              Нервозность, чувство тревоги, повышенная возбудимость, бессонница
            </li>
            <li>Частое и обильное мочеиспускание</li>
          </ul>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between gap-lg-5 mb-5">
          <h3 className="fs-6 font-bold section-custom-width1 w-75">
            Характерные признаки при анемии
          </h3>
          <ul className="fs-10 d-block text-secondary section-custom-width1">
            <li>Общая слабость</li>
            <li>Быстрая утомляемость</li>
            <li>Пониженная работоспособность, упадок сил</li>
            <li>Головокружение</li>
            <li>Шум, звон в ушах</li>
            <li>Мушки в глазах</li>
            <li>Затрудненное дыхание</li>
            <li>Потливость при умеренных физических нагрузках</li>
            <li>Бледность кожи и слизистых оболочек</li>
            <li>Усиленное сердцебиение</li>
            <li>Сухость кожи</li>
            <li>Ломкость и истончение ногтей</li>
            <li>Выпадение волос</li>
            <li>Ангулярный стоматит (заеды)</li>
            <li>Жжение языка или длительные обильные менструации</li>
          </ul>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between gap-lg-4 mb-5">
          <h3 className="font-bold fs-6 section-custom-width1">
            Характерные признаки при сахарном диабете
          </h3>
          <div>
            <p className="custom-text-color fs-10 ">
              Дополнительно рекоммендовано:
            </p>
            <p className="custom-text-color fs-10 ">
              сдать анализ на глюкозу и гликированный гемоглобин fP-gluc и
              HbA1c.
            </p>
          </div>
          <ul className="fs-10 text-secondary section-custom-width1">
            <li>Сухость слизистых оболочек</li>
            <li>Жажда</li>
            <li>Частые позывы к мочеиспусканию</li>
            <li>Бессонница</li>
            <li>Ухудшение работоспособности</li>
            <li>Частая усталость</li>
            <li>Ожирение</li>
            <li>Ухудшение зрения</li>
            <li>Головокружение</li>
            <li>Появление гнойников на коже</li>
            <li>Частые инфекционные болезни</li>
            <li>Частые инфекционные болезни</li>
            <li>Длительное заживление ран</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Simptoms;
