import React, { useEffect } from "react";
import SupplementRecommendations from "../components/other/SupplementRecommendations";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const SupplementRecommendationsPage = () => {
  useEffect(() => {
    const element = document.documentElement;
    element.scrollIntoView({ behavior: "instant", block: "start" });
  }, []);
  return (
    <>
      <Header />
      <SupplementRecommendations />
      <Footer />
    </>
  );
};

export default SupplementRecommendationsPage;
