import * as yup from "yup";
import { useFormik } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
} from "@chakra-ui/react";
import { inputType, otherFields } from "../utiles";
import Loader from "../mainPage/Loader";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utiles/baseUrl";
import Cookies from "js-cookie";

export const ManualForm = ({
  pay,
  userPayment,
  sucsses,
  disabled,
  proverka,
  showModal,
  setShowModal,
  setSucsses,
  setProverka,
  setDisabled,
  setAi,
}) => {
  const navigate = useNavigate();
  const shpToken = Cookies.get("token");
  const validationSchema = yup.object({
    Section1: yup.number().required("Обязательное поле"),
    Section2: yup.string().required("Обязательное поле"),
    Section3: yup.string().required("Обязательное поле"),
    Section4: yup.string(),
    Section5: yup.string(),
    Section6: yup.string(),
    Section7: yup.string(),
    Section8: yup.string(),
    Section9: yup.string(),
    Section10: yup.string(),
    Section11: yup.string(),
    Section12: yup.string(),
    Section13: yup.string(),
    Section14: yup.string(),
    Section15: yup.string(),
    Section16: yup.string(),
    Section17: yup.string(),
    Section18: yup.string(),
    Section19: yup.string().required("Обязательное поле"),
  });

  const formik = useFormik({
    initialValues: {
      Section1: "",
      Section2: "",
      Section3: "",
      Section4: "",
      Section5: "",
      Section6: "",
      Section7: "",
      Section8: "",
      Section9: "",
      Section10: "",
      Section11: "",
      Section12: "",
      Section13: "",
      Section14: "",
      Section15: "",
      Section16: "",
      Section17: "",
      Section18: "",
      Section19: "",

      // fake data
      // Section1: "1",
      // Section2: "муж",
      // Section3: "2",
      // Section4: "1",
      // Section5: "1",
      // Section6: "2",
      // Section7: "2",
      // Section8: "2",
      // Section9: "2",
      // Section10: "2",
      // Section11: "2",
      // Section12: "2",
      // Section13: "2",
      // Section14: "2",
      // Section15: "2",
      // Section16: "2",
      // Section17: "2",
      // Section18: "2",
      // Section19: "2",
      // tsh: "0.1",
      // free_t3: "0.2",
      // free_t4: "0.3",
      // anti_tpo: "0.4",
      // thyroid_ultrasound: "0.5",
      // glucose: "0.6",
      // insulin: "0.7",
      // fructosamine: "0.8",
      // homa_index: "0.9",
      // serum_iron: "0.10",
      // ferritin: "0.11",
      // tibc: "0.12",
      // transferrin: "0.13",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldValue }) => {
      setFieldValue("loading", true);
      let formData = {};
      for (let i = 1; i < 20; i++) {
        const field = `Section${i}`;
        if (values[field].length > 0) {
          formData[field] = values[field];
        }
      }
      try {
        setDisabled(true);
        pay();
        fetch(`${baseUrl}send-to-llm`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: shpToken, // Assuming the token is directly the value without "Bearer"
          },
          body: JSON.stringify(formData),
        })
          .then((response) => response.json())
          .then((data) => {
            setAi(data);
            if (data.response) {
              setSucsses(true);
              setDisabled(false);
            }
          });
        resetFormikState();
      } catch (error) {
        console.log(error.message);
      } finally {
        setFieldValue("loading", false);
      }
    },
  });
  const resetFormikState = () => {
    formik.resetForm();
    formik.setStatus({});
    formik.setErrors({});
    formik.setSubmitting(false);
  };

  return (
    <div>
      <form className="position-relative" onSubmit={formik.handleSubmit}>
        <div className="row p-0 d-xg-flex justify-content-center gap-lg-5 pt-3">
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            justifyContent="center"
            gap={8}
            mb={{ base: 7, lg: 0 }}
          >
            {inputType.map(({ type, name, description, isRequired }, idx) => (
              <GridItem w="100%" key={idx}>
                <FormControl
                  isRequired={isRequired}
                  isInvalid={
                    isRequired
                      ? formik.touched[name] && formik.errors[name]
                      : false
                  }
                >
                  <FormLabel
                    fontSize={{ base: 14, lg: 16 }}
                    color={
                      isRequired && formik.touched[name] && formik.errors[name]
                        ? "red.500"
                        : "inherit"
                    }
                  >
                    {description}
                  </FormLabel>
                  {type === "select" ? (
                    <Select
                      placeholder="Выберите пол (муж/жен)"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name={name}
                      value={formik.values[name]}
                    >
                      <option value="муж">Мужской</option>
                      <option value="жен">Женский</option>
                    </Select>
                  ) : (
                    <Input
                      _focus={{
                        borderColor: "brand.accent",
                        boxShadow: "0 0 0 1px rgba(240, 76, 35, 0.6)",
                      }}
                      placeholder={`Введите ${description}`}
                      value={formik.values[name]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name={name}
                      type={type}
                      sx={{
                        "::placeholder": {
                          fontSize: { base: 12, lg: 16 },
                        },
                      }}
                    />
                  )}
                  {isRequired &&
                    formik.touched[name] &&
                    formik.errors[name] && (
                      <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
                    )}
                </FormControl>
              </GridItem>
            ))}
          </Grid>
        </div>
        {showModal ? (
          <div className="container position-absolute z-3 rounded rounded-5 top-0 bottom-0 end-0 start-0  w-100 h-100">
            <div className="w-50 position-fixed top-50 start-50 translate-middl translate-middle bg-light p-3 rounded">
              <button
                className="position-absolute top-0 btn btn-light end-0 m-3"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(!showModal);
                  setSucsses(false);
                  setProverka(false);
                  setDisabled(false);
                }}
              >
                <svg
                  width="30px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
              {sucsses ? (
                <p className="text_success text-center fs-4 fw-bold paddingTop d-flex align-items-center row-gap-4 flex-column">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      userPayment();
                    }}
                    className="btn btn-secondary w-70 mt-2 text-center "
                  >
                    Просмотреть результат
                  </button>
                </p>
              ) : (
                <div className="d-flex flex-column align-items-center pb-3">
                  {disabled ? (
                    <>
                      <p className="text-black fs-4 text-center fw-bold paddingTop responsive-text">
                        Пожалуйста подождите, пока мы обрабатываем ваш запрос
                      </p>
                      <Loader />
                    </>
                  ) : (
                    <>
                      <p
                        className={`${
                          proverka ? "text-black " : "text-black "
                        } fs-4 fw-bold paddingTop text-center responsive-text`}
                      >
                        {proverka
                          ? "Спасибо оплата прошла успешно"
                          : "Проверьте все поля перед отправкой"}
                      </p>
                      <button
                        className="custom-bg custom-hover-ai border-none text-light text-center p-2 ps-4 pe-3 w-20 diseb"
                        onClick={() => (proverka ? navigate("/hemoai") : null)}
                        type={proverka ? "" : "submit"}
                      >
                        {proverka
                          ? "Просмотреть результат"
                          : "Всё верно. Отправить."}
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : null}
        <div className="row w-100 container m-0 p-0">
          <div className="d-flex justify-content-end mt-4 mb-4 p-0">
            <label className="border visually-hidden border-dark bg-light text-center pt-1 rounded px-2 opacity-25">
              Выберите файл
              <input type="file" className="visually-hidden" disabled />
            </label>
            <div className="position-relative mb-3">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(!showModal);
                }}
                className="text-decoration-none border-none fw-bold fs-10 position-absolute p-2 text-light  me-4 d-flex justify-content-center custom-positio bg-link"
              >
                Отправить
              </button>
              <span className="d-block custom-io bg-secondary"></span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
