import React from "react";

const Refund = () => {
  return (
    <div className="container">
      <h4 className="font-bold fs-6 mb-3">ВОЗВРАТЫ</h4>
      <p className="fs-10 text-secondary lh-lg">
        У нас действует 30-дневная политика возврата, что означает, что у вас
        есть 30 дней после получения услуги, чтобы запросить возврат: Для
        проведения анализа ваших данных сервис HEMO-AI (ИП LoanaAI) применяет
        технологии Искусственного Интеллекта. Чтобы воспользоваться этой
        услугой, необходимо пройти регистрацию и заполнить специальную форму.
        После её заполнения появится форма для осуществления платежа. Все
        платежи производятся через защищённую платформу для обеспечения
        безопасности ваших финансовых данных. Отправьте запрос к Искусственному Интеллекту, и вы
        получите детализированный ответ с результатами расшифровки ваших анализов. В случае, если
        ответ от Искусственного Интеллекта не будет предоставлен вообще, вы имеете право на возврат
        средств за не оказанные услуги в течении 30 дней.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        По любым вопросам возврата вы всегда можете связаться с нами по адресу{" "}
        <a
          className="text-decoration custom-text-color font-bold"
          href="mailto:info@hemo-ai.kz"
        >
          admin@hemo-ai.kz.
        </a>
      </p>
      <p className="fs-10 text-secondary lh-lg">Свяжитесь с Нами</p>
      <p className="fs-10 text-secondary lh-lg">
        Если с момента одобрения вашего возврата прошло более 15 рабочих дней,
        свяжитесь с нами по адресу{" "}
        <a
          className="text-decoration custom-text-color font-bold"
          href="mailto:info@hemo-ai.kz"
        >
          admin@hemo-ai.kz.
        </a>
      </p>
    </div>
  );
};

export default Refund;
