import React from "react";
import burger from "./img/icons/burger.svg";
import logo from "./img/HEMO-AI.png";
import { Link } from "react-router-dom";
import "./Header.css";
import { useSelector } from "react-redux";

const HeaderBottom = ({ navbarItem, pathname, showModal, openMenu }) => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  return (
    <div className="container mt-4 position-sticky">
      <nav className="header__bottom pt-1">
        <ul className="list-type d-flex gap-4 pt-2 justify-content-between justify-content-xl-between align-items-center">
          <li className="">
            {isAuthorized ? (
              <Link to="/hemo">
                <img className="custom-img" src={logo} alt="Hemo-ai" />
              </Link>
            ) : (
              <Link>
                <img className="custom-img" src={logo} alt="Hemo-ai" />
              </Link>
            )}
          </li>
          {navbarItem.map((item, index) => {
            const isActive = pathname === item.to;
            return (
              <li className="d-none d-xl-block" key={index}>
                <Link
                  className={`${
                    isActive ? "custom-color fs-10" : "text-black"
                  } text-decoration-none pe-2 me-1 fw-bold fs-10 custom-img text-center`}
                  to={item.to}
                >
                  {item.text}
                </Link>
              </li>
            );
          })}
          <li className="d-none d-xl-block position-relative">
            <Link
              className="text-decoration-none fw-bold fs-10 position-absolute p-2 text-light bg-dark  d-flex justify-content-center custom-position"
              to="/contacts"
            >
              Контакты
            </Link>
            <span className="d-block custom-i bg-secondary"></span>
          </li>
          <li className="d-xl-none">
            <button
              onClick={showModal}
              className="custom-bg border border-0 p-2"
            >
              <img src={burger} alt="Menu" />
            </button>
          </li>
        </ul>
        {openMenu ? (
          <ul className="list-type d-xl-none mt-4 burger_menu">
            {navbarItem.map((item, index) => {
              const isActive = pathname === item.to;
              return (
                <li
                  className={`${isActive ? "custom-bg text-white " : ""}
                border-bottom`}
                  key={index}
                >
                  <Link
                    className={`${
                      isActive ? "text-white " : ""
                    } text-decoration-none  custom-color p-1 custom-full`}
                    to={item.to}
                  >
                    {item.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}
      </nav>
    </div>
  );
};

export default HeaderBottom;
