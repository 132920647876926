import React from "react";
import "./Header.css";
import { Link, NavLink } from "react-router-dom";
import phone from "./img/icons/phone.svg";
import email from "./img/icons/email.svg";
import instagram from "./img/icons/instagram.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as authSlice from "../../store/authSlice";

const HeaderTop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch(authSlice.logout());
    navigate("/");
  };
  return (
    <div className="container d-flex justify-content-between align-items-center pt-2">
      <div className="d-xl-flex align-items-center gap-3 custom-header-top custom-width z-2">
        <a
          href="tel:+77079245615"
          className="z-3 header__info d-flex align-items-center gap-2 text-light custom-fs text-decoration-none font-weight-semibold text-3-5"
        >
          <img className="mb-1 custom-blink" src={phone} alt="telephone" />
          +7 707 924 56 15
        </a>
        <a
          href="mailto:info@hemo-ai.kz"
          className="z-3 header__info d-none d-xl-flex align-items-center gap-1 text-light custom-fs text-decoration-none font-weight-semibold text-3-5 ms-2"
        >
          <img className="mb-1 custom-blink" src={email} alt="email" />
          info@hemo-ai.kz
        </a>
      </div>
      <div className="d-flex gap-4 align-items-baseline">
        <a
          href="http://www.instagram.com/hemoai"
          target="_blank"
          className="text-4 custom-hover"
          title="Instagram"
          rel="noreferrer"
        >
          <img src={instagram} alt="Instagram" />
        </a>
        <div className="d-flex align-items-center">
          {isAuthorized ? (
            <div>
              <NavLink
                to="/hemo"
                className="custom-color text-decoration-none font-weight-semibold text-3-5 ms-2 border-none bacground-transperent mx-2"
              >
                Твой советник
              </NavLink>
              <button
                onClick={handleLogout}
                className="custom-color text-decoration-none font-weight-semibold text-3-5 ms-2 border-none bacground-transperent"
              >
                Выйти
              </button>
            </div>
          ) : (
            <>
              <Link
                className="custom-color text-decoration-none font-weight-semibold text-3-5 ms-2"
                to="/#login"
              >
                Войти
              </Link>
              <span className="custom-slash"> </span>
              <Link
                className="custom-color text-decoration-none font-weight-semibold text-3-5"
                to="/registration"
              >
                Регистрация
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
