import {
  Box,
  Button,
  Divider,
  Fade,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { lighten } from "polished";
import { useRef, useState } from "react";
import { IndividualTest } from "./IndividualTest";
import { IndividualCategories } from "./IndividualCategories";
import { IndividualManualForm } from "./IndividualManualForm";
import Cookies from "js-cookie";
import { customAlphabet } from "nanoid";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../utiles/baseUrl";
import { setResponseAi } from "../../../../store/authSlice";

const textsRecom = [
  "1. Выберите свою цель из списка.",
  "2. Пройдите проверку симптомов за 5 минут.",
  "3. Изучите рекомендации после предварительной оценки симптомов. Если у вас есть результаты анализов, загрузите их для дальнейшего анализа.",
  "4. Получите полные индивидуальные рекомендации по достижению цели.",
  "5. Начните достигать поставленной цели вместе с нами!",
];

const id = customAlphabet("1234567890", 8);
let numberId = id();
const merchantLogin = process.env.REACT_APP_MERCHANT_LOGIN;
const description = "Техническая документация по ROBOKASSA";
const outSum = 850.0;

const categories = ["metob", "derm", "neuro", "cardio", "gastro"];

export const Individual = () => {
  const dispatch = useDispatch();
  const Robokassa = window.Robokassa;
  const [proverka, setProverka] = useState(false);
  const [ai, setAi] = useState();
  const shpToken = Cookies.get("token");
  const emai = localStorage.getItem("user");
  const email = JSON.parse(emai);
  const [showModal, setShowModal] = useState(false);
  const [sucsses, setSucsses] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modal, SetModal] = useState(false);
  const testsContainer = useRef(null);

  const [result, setResult] = useState({
    metob: [0, 0, 0],
    derm: [0, 0, 0],
    neuro: [0, 0, 0],
    cardio: [0, 0, 0],
    gastro: [0, 0, 0],
  });

  const [symptoms, setSymtoms] = useState({
    anemia: false,
    diabet: false,
    hypothyroidism: false,
  });
  const [isFinished, setIsFinished] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [activeStep, setStep] = useState(0);
  const [categoryIdx, setCategoryIdx] = useState(0);

  const userPayment = () => {
    const token = shpToken;

    const paymentIdString = localStorage.getItem("paymentId");

    // Парсинг строки JSON в объект
    const paymentIdObject = JSON.parse(paymentIdString);
    // Теперь paymentIdObject содержит объект с вашими данными
    const id = paymentIdObject.INVID.toString();
    const invId = {
      InvId: id,
    };

    fetch(`${baseUrl}user/payments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token, // Убедитесь, что 'token' определен корректно
      },
      body: JSON.stringify(invId),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.InvId) {
          dispatch(setResponseAi(ai.response));
          setProverka(true);
          setSucsses(false);
        } else {
          SetModal(true);
          setTimeout(() => SetModal(false), 5000);
        }
      })
      .catch((e) => {
        SetModal(true);
        setTimeout(() => SetModal(false), 5000);
      });
  };

  const pay = async () => {
    try {
      const invId = Number(numberId);
      localStorage.setItem("paymentId", JSON.stringify({ INVID: invId }));
      // Start the fetch request and wait for it to complete
      const response = await fetch(`${baseUrl}user/protected_resource`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: shpToken,
        },
        body: JSON.stringify({
          outSum: outSum,
          invId: invId,
          shpToken: shpToken,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const signatureValue = data.signatureValue;
      Robokassa.StartPayment({
        MerchantLogin: merchantLogin,
        OutSum: outSum,
        Email: email.email,
        InvId: invId,
        Description: description,
        Culture: "ru",
        Encoding: "utf-8",
        SignatureValue: signatureValue,
        Shp_token: shpToken,
      });
      setTimeout(() => {
        setShowModal(true);
      }, 8000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const scrollToElement = () => {
    if (testsContainer.current) {
      testsContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const checkSymptoms = () => {
    const keys = Object.keys(result);

    let anemia = false;
    let diabet = false;
    let hypothyroidism = false;

    for (let key of keys) {
      if (result[key][0] > 0) anemia = true;
      if (result[key][1] > 0) diabet = true;
      if (result[key][2] > 0) hypothyroidism = true;
    }

    setSymtoms({
      anemia,
      diabet,
      hypothyroidism,
    });
  };

  const handleChangeResult = (value, action) => {
    const resArray = [];
    if (action === "yes") {
      for (let i = 0; i < value.length; i++) {
        resArray.push(result[categories[categoryIdx]][i] + value[i]);
      }
    } else {
      for (let i = 0; i < value.length; i++) {
        resArray.push(result[categories[categoryIdx]][i] - value[i]);
      }
    }
    setResult((prevState) => ({
      ...prevState,
      [categories[categoryIdx]]: resArray,
    }));
  };

  const handleFinish = async () => {
    try {
      checkSymptoms();
      setIsFinished(true);
      await fetch(`${baseUrl}user/symptoms_check`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: shpToken,
        },
        body: JSON.stringify(result),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div data-aos="fade" data-aos-delay={200} data-aos-anchor-placement="null">
      <div className="d-flex flex-column align-items-center pt-5">
        <span className="custom-letter custom-text-color">
          Индивидуальные рекомендации (бета версия)
        </span>
        <p className="fs-4 font-bold w-80 pt-2 text-center">
          Получите индивидуальные рекомендации для достижения целей за 5 простых
          шага:
        </p>
        <Box sx={{ mb: "45px", width: "80%", maxWidth: "800px", mx: "auto" }}>
          {textsRecom.map((value, idx) => (
            <p className="fs-5" style={{ textAlign: "left" }} key={idx}>
              {value}
            </p>
          ))}
        </Box>

        {/* Цели рекомендациии  1,2,3*/}
        <Box
          sx={{
            width: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "40px",
            mb: "32px",
          }}
        >
          <Divider
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "100%",
              margin: 0,
              borderWidth: "1px",
              borderColor: "brand.accent",
            }}
          />

          <Button
            onClick={() => {
              setStep(1);
              setTimeout(scrollToElement, 0); // Scroll after updating the state
            }}
            sx={{
              height: "auto", // Adjust height automatically based on content
              minHeight: "180px", // Minimum height to maintain design
              background: "brand.accent",
              color: "#fff",
              fontSize: { base: "15px", md: "17px", lg: "20px" }, // Responsive font size
              width: { base: "90%", sm: "250px", md: "300px" }, // Responsive button width
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              padding: "10px 40px", // Adjusted padding
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
              whiteSpace: "normal", // Allows text to wrap
              overflowWrap: "break-word", // Ensures text breaks to avoid overflow
            }}
          >
            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
              whiteSpace="nowrap" // Prevents the text from wrapping
            >
              Цель 1
            </Text>

            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
            >
              Улучшение общего состояния
            </Text>
          </Button>
          <Button
            onClick={() => {
              setStep(1);
              setTimeout(scrollToElement, 0); // Scroll after updating the state
            }}
            sx={{
              height: "auto", // Adjust height automatically based on content
              minHeight: "180px", // Minimum height to maintain design
              background: "brand.accent",
              color: "#fff",
              fontSize: { base: "15px", md: "17px", lg: "20px" }, // Responsive font size
              width: { base: "90%", sm: "250px", md: "300px" }, // Responsive button width
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              padding: "10px 40px", // Adjusted padding
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
              whiteSpace: "normal", // Allows text to wrap
              overflowWrap: "break-word", // Ensures text breaks to avoid overflow
            }}
          >
            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
              whiteSpace="nowrap" // Prevents the text from wrapping
            >
              Цель 2
            </Text>

            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
            >
              Здоровье печени
            </Text>
          </Button>
          <Button
            onClick={() => {
              setStep(1);
              setTimeout(scrollToElement, 0); // Scroll after updating the state
            }}
            sx={{
              height: "auto", // Adjust height automatically based on content
              minHeight: "180px", // Minimum height to maintain design
              background: "brand.accent",
              color: "#fff",
              fontSize: { base: "15px", md: "17px", lg: "20px" }, // Responsive font size
              width: { base: "90%", sm: "250px", md: "300px" }, // Responsive button width
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              padding: "10px 40px", // Adjusted padding
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
              whiteSpace: "normal", // Allows text to wrap
              overflowWrap: "break-word", // Ensures text breaks to avoid overflow
            }}
          >
            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
              whiteSpace="nowrap" // Prevents the text from wrapping
            >
              Цель 3
            </Text>

            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
            >
              Здоровье кишечника
            </Text>
          </Button>
        </Box>

        {/* Цели рекомендациии 4,5,6 */}
        <Box
          sx={{
            width: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "40px",
            mb: "32px",
          }}
        >
          <Divider
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "100%",
              margin: 0,
              borderWidth: "1px",
              borderColor: "brand.accent",
            }}
          />

          <Button
            onClick={() => {
              setStep(1);
              setTimeout(scrollToElement, 0); // Scroll after updating the state
            }}
            sx={{
              height: "auto", // Adjust height automatically based on content
              minHeight: "180px", // Minimum height to maintain design
              background: "brand.accent",
              color: "#fff",
              fontSize: { base: "15px", md: "17px", lg: "20px" }, // Responsive font size
              width: { base: "90%", sm: "250px", md: "300px" }, // Responsive button width
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              padding: "10px 40px", // Adjusted padding
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
              whiteSpace: "normal", // Allows text to wrap
              overflowWrap: "break-word", // Ensures text breaks to avoid overflow
            }}
          >
            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
              whiteSpace="nowrap" // Prevents the text from wrapping
            >
              Цель 4
            </Text>

            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
            >
              -
            </Text>
          </Button>
          <Button
            onClick={() => {
              setStep(1);
              setTimeout(scrollToElement, 0); // Scroll after updating the state
            }}
            sx={{
              height: "auto", // Adjust height automatically based on content
              minHeight: "180px", // Minimum height to maintain design
              background: "brand.accent",
              color: "#fff",
              fontSize: { base: "15px", md: "17px", lg: "20px" }, // Responsive font size
              width: { base: "90%", sm: "250px", md: "300px" }, // Responsive button width
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              padding: "10px 40px", // Adjusted padding
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
              whiteSpace: "normal", // Allows text to wrap
              overflowWrap: "break-word", // Ensures text breaks to avoid overflow
            }}
          >
            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
              whiteSpace="nowrap" // Prevents the text from wrapping
            >
              Цель 5
            </Text>

            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
            >
              -
            </Text>
          </Button>
          <Button
            onClick={() => {
              setStep(1);
              setTimeout(scrollToElement, 0); // Scroll after updating the state
            }}
            sx={{
              height: "auto", // Adjust height automatically based on content
              minHeight: "180px", // Minimum height to maintain design
              background: "brand.accent",
              color: "#fff",
              fontSize: { base: "15px", md: "17px", lg: "20px" }, // Responsive font size
              width: { base: "90%", sm: "250px", md: "300px" }, // Responsive button width
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              padding: "10px 40px", // Adjusted padding
              _hover: {
                background: lighten(0.1, "#F04C23"),
              },
              whiteSpace: "normal", // Allows text to wrap
              overflowWrap: "break-word", // Ensures text breaks to avoid overflow
            }}
          >
            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
              whiteSpace="nowrap" // Prevents the text from wrapping
            >
              Цель 6
            </Text>

            <Text
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              textAlign="center"
            >
              -
            </Text>
          </Button>
        </Box>

        {/* тест на симптомы */}
        {activeStep > 0 && (
          <Fade in style={{ width: "100%" }}>
            <Box
              ref={testsContainer}
              sx={{
                marginTop: "32px",
                width: "100%",
                padding: "20px 30px",
                background: "#E2E2E2",
                color: "black",
                borderRadius: "24px",
              }}
            >
              <Text
                sx={{
                  fontSize: { base: 32, lg: 48 },
                  color: "brand.accent",
                  padding: "32px 0 32px 32px",
                }}
              >
                Проверка симптомов
              </Text>

              {activeStep === 1 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { base: "column", xl: "row" },
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <IndividualCategories
                    category={categories[categoryIdx]}
                    onSetCategory={(value) => {
                      setCategoryIdx(value);
                      scrollToElement();
                    }}
                  />
                  {/* {JSON.stringify(result)} */}
                  {/* {JSON.stringify(symptoms)} */}

                  <IndividualTest
                    category={categories[categoryIdx]}
                    onClick={() => {
                      setCategoryIdx((prevState) =>
                        prevState + 1 < categories.length
                          ? prevState + 1
                          : prevState
                      );
                      scrollToElement();
                    }}
                    onChangeResult={(value, action) =>
                      handleChangeResult(value, action)
                    }
                    onFinish={handleFinish}
                    isLastTest={categoryIdx + 1 === categories.length}
                  />
                </Box>
              )}

              {(activeStep === 2 ||
                activeStep === 3 ||
                activeStep === 4 ||
                activeStep === 5 ||
                activeStep === 6) && (
                <Text
                  sx={{
                    fontSize: { base: 15, lg: 18 },
                  }}
                >
                  Спасибо за проявленный интерес! <br /> В данный момент мы
                  работаем над алгоритмами, чтобы помочь вам в достижении этих
                  целей.
                </Text>
              )}
            </Box>
          </Fade>
        )}

        {/* наши рекомендации */}
        {isFinished &&
          (symptoms.anemia || symptoms.diabet || symptoms.hypothyroidism) && (
            <Fade in style={{ width: "100%" }}>
              <Box
                ref={testsContainer}
                sx={{
                  marginTop: "32px",
                  width: "100%",
                  padding: { base: "10px 15px", xl: "20px 30px" },
                  background: "#E2E2E2",
                  color: "black",
                  borderRadius: "24px",
                }}
              >
                <Text
                  sx={{
                    fontSize: { base: 32, lg: 48 },
                    color: "brand.accent",
                    padding: "32px 0 32px 32px",
                  }}
                >
                  Наши рекомендации
                </Text>

                <Box>
                  <Text
                    sx={{
                      fontSize: { base: 16, lg: 20 },
                      padding: "16px",
                      fontWeight: 600,
                      mb: 0,
                      pb: 0,
                    }}
                  >
                    Ваши симптомы указывают на возможную
                    {symptoms.anemia && " анемию, "}
                    {symptoms.diabet && " гипотериоз,"}
                    {symptoms.hypothyroidism &&
                      " проблемы с щитовидной железой."}
                  </Text>
                  <Text
                    sx={{
                      fontSize: { base: 16, lg: 19 },
                      padding: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Для полной оценки рекомендуется сдать следующие анализы и
                    сходить к врачу:
                  </Text>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 2,
                    }}
                  >
                    {symptoms.hypothyroidism && (
                      <Box sx={{ background: "#fff", padding: "20px 30px" }}>
                        <Text
                          sx={{
                            fontSize: { base: 14, lg: 18 },
                            fontWeight: 600,
                            mb: "12px",
                          }}
                        >
                          Гипотиреоз
                        </Text>
                        <UnorderedList sx={{ margin: 0, pl: "18px" }}>
                          <ListItem>ТТГ</ListItem>
                          <ListItem>Т3 свободный</ListItem>
                          <ListItem>Т4 свободный</ListItem>
                          <ListItem>ат-тпо</ListItem>
                          <ListItem>узи щитовидной железы</ListItem>
                        </UnorderedList>
                      </Box>
                    )}
                    {symptoms.diabet && (
                      <Box sx={{ background: "#fff", padding: "20px 30px" }}>
                        <Text
                          sx={{
                            fontSize: { base: 14, lg: 18 },
                            fontWeight: 600,
                            mb: "12px",
                          }}
                        >
                          Диабет
                        </Text>
                        <UnorderedList sx={{ margin: 0, pl: "18px" }}>
                          <ListItem>глюкоза</ListItem>
                          <ListItem>инсулин</ListItem>
                          <ListItem>фруктозамин или <br /> если HGB - Гемоглобин в норме, <br /> то можно сдать Гликированный Гемоглобин</ListItem>

                        </UnorderedList>
                      </Box>
                    )}
                    {symptoms.anemia && (
                      <Box sx={{ background: "#fff", padding: "20px 30px" }}>
                        <Text
                          sx={{
                            fontSize: { base: 14, lg: 18 },
                            fontWeight: 600,
                            mb: "12px",
                          }}
                        >
                          Анемия
                        </Text>
                        <UnorderedList sx={{ margin: 0, pl: "18px" }}>
                          <ListItem>ОАК</ListItem>
                          <ListItem>железо в сыворотке</ListItem>
                          <ListItem>ферритин</ListItem>
                          <ListItem>ОЖСС</ListItem>
                          <ListItem>трансферрин</ListItem>
                        </UnorderedList>
                      </Box>
                    )}
                  </Box>
                  <Text
                    sx={{
                      fontSize: { base: 16, lg: 19 },
                      padding: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Также вы можете загрузить ваши анализы и получить дальнейшие
                    рекомендации для достижения поставленной цели. Мы
                    рекомендуем обязательно обратиться к врачу. Прочтите
                    информацию о возможных осложнениях.
                  </Text>
                  {symptoms.anemia && (
                    <Text
                      sx={{
                        fontSize: { base: 16, lg: 19 },
                        padding: "16px",
                        fontWeight: 400,
                      }}
                    >
                      Анемия — это состояние, при котором уровень здоровых
                      красных кровяных телец (эритроцитов) или гемоглобина в
                      крови ниже нормы. Гемоглобин, основная часть красных
                      кровяных телец, связывает кислород. Если у вас анемия,
                      вашему организму не хватает кислорода для поддержания
                      нормального функционирования органов и тканей.
                      Профилактика анемии включает сбалансированное питание,
                      богатое железом, витамином B12, фолиевой кислотой и
                      другими питательными веществами. Регулярные медицинские
                      осмотры также могут помочь выявить и лечить анемию на
                      ранней стадии. Необработанная анемия может привести к ряду
                      серьезных осложнений, которые могут значительно повлиять
                      на качество и продолжительность жизни. Вот некоторые из
                      возможных осложнений:
                      <ul>
                        <li>
                          {" "}
                          Ухудшение сердечно-сосудистой системы: Хроническая
                          анемия заставляет сердце работать тяжелее, чтобы
                          компенсировать недостаток кислорода в крови, что может
                          привести к увеличению сердца или сердечной
                          недостаточности.
                        </li>
                        <li>
                          Проблемы с беременностью: У беременных женщин анемия
                          связана с преждевременными родами, низким весом при
                          рождении и материнской смертностью.
                        </li>
                        <li>
                          Задержка роста и развития у детей: У детей с анемией
                          могут наблюдаться задержки в физическом и когнитивном
                          развитии.
                        </li>
                        <li>
                          Увеличение риска инфекций: Анемия может ослабить
                          иммунную систему, делая организм более уязвимым к
                          инфекциям..
                        </li>
                        <li>
                          {" "}
                          Ухудшение когнитивных функций: Длительная анемия может
                          повлиять на когнитивные функции, приводя к проблемам с
                          концентрацией и памятью.
                        </li>
                      </ul>
                    </Text>
                  )}
                  {symptoms.hypothyroidism && (
                    <Text
                      sx={{
                        fontSize: { base: 16, lg: 19 },
                        padding: "16px",
                        fontWeight: 400,
                      }}
                    >
                      Гипотиреоз — это состояние, при котором щитовидная железа
                      не производит достаточное количество гормонов, необходимых
                      для нормального метаболизма и функционирования организма.
                      Это распространенное заболевание, особенно среди женщин
                      среднего и старшего возраста. Если гипотиреоз остается
                      необнаруженным и не лечится, он может привести к ряду
                      серьезных осложнений:
                      <ul>
                        <li>
                          Микседема: Это редкое, но опасное для жизни состояние,
                          которое может произойти при длительном недостатке
                          гормонов щитовидной железы. Симптомы включают
                          интенсивное охлаждение, сонливость, путаницу и даже
                          кому.
                        </li>
                        <li>
                          Сердечно-сосудистые заболевания: Гипотиреоз может
                          привести к повышенному уровню "плохого" холестерина
                          (ЛПНП), что увеличивает риск развития атеросклероза и
                          сердечных заболеваний.
                        </li>
                        <li>
                          Проблемы с психическим здоровьем: Гипотиреоз часто
                          связан с депрессией.
                        </li>
                        <li>
                          Периферическая нейропатия: Длительный дефицит гормонов
                          может повредить периферические нервы, что приводит к
                          боли, онемению и покалыванию в затронутых областях.
                        </li>
                        <li>
                          Бесплодие и осложнения во время беременности:
                          Гипотиреоз может повлиять на способность зачать
                          ребенка и привести к осложнениям во время
                          беременности, включая преждевременные роды, высокое
                          кровяное давление во время беременности и развитие
                          гипотиреоза у новорожденного.
                        </li>
                      </ul>
                    </Text>
                  )}
                  {symptoms.diabet && (
                    <Text
                      sx={{
                        fontSize: { base: 16, lg: 19 },
                        padding: "16px",
                        fontWeight: 400,
                      }}
                    >
                      Сахарный диабет — это хроническое заболевание, которое
                      возникает, когда поджелудочная железа не производит
                      достаточно инсулина или когда организм не может эффективно
                      использовать произведенный инсулин. Инсулин — это гормон,
                      который регулирует уровень сахара (глюкозы) в крови.
                      Высокий уровень глюкозы в крови (гипергликемия) может
                      приводить к серьезным повреждениям органов. Необработанный
                      или плохо контролируемый диабет может привести к ряду
                      острых и хронических осложнений:
                      <ul>
                        <li>
                          {" "}
                          Кардиоваскулярные заболевания: диабет значительно
                          увеличивает риск развития различных
                          сердечно-сосудистых проблем, включая ишемическую
                          болезнь сердца, инфаркт миокарда, инсульт и
                          атеросклероз.
                        </li>
                        <li>
                          Нейропатия (повреждение нервов): Избыток сахара может
                          повредить стенки кровеносных сосудов, питающих нервы,
                          что может привести к покалыванию, онемению, жжению или
                          боли, которая обычно начинается на кончиках пальцев
                          ног или рук и постепенно распространяется вверх.
                        </li>
                        <li>
                          Нефропатия (повреждение почек): Диабет может повредить
                          систему очистки крови в почках, что ведет к их
                          неспособности фильтровать отходы, что может привести к
                          почечной недостаточности или необратимому конечному
                          стадии почечной болезни.
                        </li>
                        <li>
                          Ретинопатия (повреждение сетчатки глаза): Диабет может
                          привести к повреждению кровеносных сосудов сетчатки,
                          что может вызвать слепоту.{" "}
                        </li>
                        <li>
                          Кожные инфекции: Диабетики чаще страдают от кожных
                          инфекций и кожных заболеваний.
                        </li>
                      </ul>
                    </Text>
                  )}
                  <Text
                    sx={{
                      fontSize: { base: 16, lg: 19 },
                      padding: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Уже есть анализы? То отправьте их нам, чтобы получить полную
                    оценку ваших показателей и рекомендации.
                  </Text>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{
                        background: "brand.accent",
                        color: "#fff",
                        _hover: {
                          background: lighten(0.1, "#F04C23"),
                        },
                      }}
                      size="lg"
                      disabled={disabled} // This should correctly disable the button based on state
                      onClick={() => {
                        setOpenForm(true);
                        setTimeout(scrollToElement, 100);
                      }}
                    >
                      Отправить анализы
                    </Button>{" "}
                  </Box>
                </Box>
              </Box>
            </Fade>
          )}

        {isFinished &&
          !symptoms.anemia &&
          !symptoms.diabet &&
          !symptoms.hypothyroidism && (
            <Fade in>
              <Box
                sx={{
                  padding: "20px 30px",
                  background: "#E2E2E2",
                  color: "green",
                  mt: 4,
                  fontSize: { base: 14, lg: 24 },
                }}
              >
                У вас по вашим симптомам ничего не выявлено!
              </Box>
            </Fade>
          )}
        {openForm && (
          <Fade in>
            {modal ? (
              <div className="container position-absolute rounded rounded-3 top-25 end-0 w-25 h">
                Оплатите чтобы продолжить
              </div>
            ) : null}

            <div
              data-aos="fade"
              data-aos-delay={200}
              data-aos-anchor-placement="null"
              data-aos-once="false"
            >
              <div ref={testsContainer} className="form-container">
                <span className="custom-letter custom-text-color fs-5 font-bold w-80 pt-3 text-center"></span>
                <p className="fs-5 font-bold w-80 pt-3 text-center">
                  Заполните ваши медицинские данные внизу, чтобы получить оценку
                  организма от Искусственного Интеллекта
                </p>
                <p className="fs-7 w-70 text-center">
                  Ваши медицинские данные вы можете предоставить, заполнив
                  показатели вручную.
                </p>
              </div>

              <Tabs pt={5}>
                <TabList>
                  <Tab
                    width="100%"
                    p={4}
                    _selected={{
                      color: "brand.accent",
                      borderColor: "brand.accent",
                      bg: "transparent",
                    }}
                  >
                    Заполнить вручную
                  </Tab>
                </TabList>

                <TabPanels sx={{ mb: "60px", px: 0 }}>
                  <TabPanel sx={{ p: 0 }}>
                    <IndividualManualForm
                      userPayment={userPayment}
                      sucsses={sucsses}
                      proverka={proverka}
                      disabled={disabled}
                      showModal={showModal}
                      setDisabled={setDisabled}
                      setShowModal={setShowModal}
                      setProverka={setProverka}
                      setSucsses={setSucsses}
                      setAi={setAi}
                      pay={pay}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </Fade>
        )}
      </div>
    </div>
  );
};
