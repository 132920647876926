import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    y: 200,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const Servis = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      id="servis"
      className="pb-5 pt-5 container mt-5 mb-5"
    >
      <div className="text-center">
        <motion.span
          custom={1}
          variants={textAnimation}
          className="custom-letter custom-text-color font-bold text-center"
        >
          ИНФОРМАЦИЯ О СЕРВИСАХ
        </motion.span>
        <motion.h3
          custom={2}
          variants={textAnimation}
          className="fs-2 mt-2 font-bold pt-1 pb-4 text-center"
        >
          Сервисы и цена
        </motion.h3>
        <div className="d-flex justify-content-center pb-4">
          <motion.p
            custom={3}
            variants={textAnimation}
            className="fs-10  text-secondary text-center custom-width-servise"
          >
            Здесь вы можете получить информацию о ценах и рекомендации как подготовиться к сдаче анализа.
          </motion.p>
        </div>
      </div>
      <motion.div
        custom={4}
        variants={textAnimation}
        className="d-flex flex-column flex-lg-row justify-content-around gap-3 me-3"
      >
        <Link
          to="page1"
          className="section-custom-width ms-3 me-3 text-decoration"
        >
          <div>
            <div className="d-flex gap-2 position-relative">
              <div className="position-relative  visibitity">
                {" "}
                <p className="w-75 mb-0 p-4 fs-4 lh-1 text-light font-bold position-relative z-2">
                  Расшифровка общего анализа крови <br />
                  550 тенге
                </p>
              </div>
              <span className="d-block font-bold pt-4 ps-4 h-100 custom-transperent w-100 fs-5 position-absolute text-light d-flex flex-column justify-content-center align-items-start lh-sm">
                <div className="w-25 ower position-relative">
                  <div className="zigzag-animation w-100"></div>
                </div>
              </span>
            </div>
            <Link
              to="page1"
              className="d-block text-decoration m-4 fs-6 font-bold text-black d-flex justify-content-between"
            >
          <span>Подробнее о подготовке к сдаче анализа</span>
              <svg
                className="d-inline-block ms-3"
                height="32px"
                viewBox="0 0 32 32"
                width="32px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="m0 0h32v32h-32z" />
                  <path
                    d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
                    fill="#000"
                    transform="matrix(-1 0 0 -1 32.04264 31.985282)"
                  />
                </g>
              </svg>
            </Link>
          </div>
        </Link>
        <Link
          to="page2"
          className="section-custom-width ms-3 me-3 text-decoration"
        >
          <div>
            <div className="d-flex gap-2 position-relative">
              <div className="position-relative  dobavki-bg">
                {" "}
                <p className="w-75 mb-0 p-4 fs-4 lh-1 text-light font-bold position-relative z-2">
                  Рекомендации по витаминам и диетам на основе анализов и симптомов
                  <br />
                  1850 тенге
                </p>
              </div>
              <span className="d-block font-bold pt-4 ps-4 h-100 custom-transperent w-100 fs-5 position-absolute text-light d-flex flex-column justify-content-center align-items-start lh-sm">
                <div className="w-25 ower position-relative">
                  <div className="zigzag-animation w-100"></div>
                </div>
              </span>
            </div>
            {/* <Link
              to="page2"
              className="d-block text-decoration m-4 fs-6 font-bold text-black d-flex justify-content-between"
            >
            <span>Подробнее о витаминах и минералах </span>
              <svg
                className="d-inline-block ms-3"
                height="32px"
                viewBox="0 0 32 32"
                width="32px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="m0 0h32v32h-32z" />
                  <path
                    d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
                    fill="#000"
                    transform="matrix(-1 0 0 -1 32.04264 31.985282)"
                  />
                </g>
              </svg>
            </Link> */}
          </div>
        </Link>
        <Link
          to="page3"
          className="section-custom-width ms-3 me-3 text-decoration"
        >
          <div>
            <div className="d-flex gap-2 position-relative">
              <div className="position-relative  netyr-bg">
                {" "}
                <p className="w-75 mb-0 p-4 fs-4 lh-1 text-light font-bold position-relative z-2">
                 Месячная подписка <br />
                  от 5000 тенге
                </p>
              </div>
              <span className="d-block font-bold pt-4 ps-4 h-100 custom-transperent w-100 fs-5 position-absolute text-light d-flex flex-column justify-content-center align-items-start lh-sm">
                <div className="w-25 ower position-relative">
                  <div className="zigzag-animation w-100"></div>
                </div>
              </span>
            </div>
            {/* <Link
              to="page3"
              className="d-block text-decoration m-4 fs-6 font-bold text-black d-flex justify-content-between"
            >
              <span>Подробнее о пакетах</span>
              <svg
                className="d-inline-block ms-3"
                height="32"
                viewBox="0 0 32 32"
                width="32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="m0 0h32v32h-32z" />
                  <path
                    d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
                    fill="#000"
                    transform="matrix(-1 0 0 -1 32.04264 31.985282)"
                  />
                </g>
              </svg>
            </Link> */}
          </div>
        </Link>
      </motion.div>
    </motion.section>
  );
};

export default Servis;
