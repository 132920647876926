import React, { useState } from "react";
import "./LoginForm.css";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { login } from "../../store/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../utiles/baseUrl";

const textAnimation = {
  hidden: {
    y: 150,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const inputType = [
  {
    id: 1,
    type: "email",
    name: "email",
    description: "Email",
    gap: "gap-3",
  },
  {
    id: 2,
    type: "password",
    name: "password",
    description: "Password",
    gap: "",
  },
];

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    inputType.forEach((input) => {
      if (!formData[input.name]) {
        newErrors[
          input.name
        ] = `Пожалуйста введите ${input.description.toLowerCase()}`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors(newErrors);
      const response = await fetch(`${baseUrl}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message) {
        localStorage.setItem("user", JSON.stringify({ email: formData.email }));
        Cookies.set("token", data.token);
        dispatch(login(formData.email));
        navigate("/hemo");
      } else if (data.error) {
        setErrors(data);
      }
    }
  };

  // initial="hidden"
  // whileInView="visible"
  // viewport={{ once: true, amount: 0.4 }}

  return (
    <div className="custom-gradient-login position-relative login">
      <div className="container p-3 text-light ">
        <div className="login__content">
          <h2 className="font-bold text-light line-height-2  d-none d-lg-block custom-bg-text login__title">
            ДОБРО ПОЖАЛОВАТЬ В
          </h2>
          <h2 className="font-bold text-light line-height-2 d-none d-lg-block custom-bg-text login__title">
            HEMO-AI
          </h2>
          <h2 className="font-bold line-height-2 d-lg-none custom-text-color custom-letter login__mobile-title ">ДОБРО ПОЖАЛОВАТЬ</h2>
          <h3
            variants={textAnimation}
            custom={2}
            className="fs-1 mt-0 login__subtitle"
          >
            Зарегистрируйтесь и получите больше информации о вашем здоровье от
            Искусственного Интеллекта
          </h3>
          <motion.p
            variants={textAnimation}
            custom={3}
            className="custom-fs text-secondary mt-3 login__text"
          >
            Зарегистрируйтесь и получите оценку вашего организма и рекомендации
            для достижения индивидуальных целей.
          </motion.p>
        </div>
        <div className="login__form">
          <div id="login" className="pt-5">
            <form className="" onSubmit={handleFormSubmit}>
              <h2 className="login__form-title">Авторизация</h2>
              <div className=" ">
                {inputType.map((input) => (
                  <div key={input.id} className="mb-4 ">
                    <label
                      htmlFor={input.name}
                      className="form-label text-wrap text-break"
                      style={{ overflowWrap: "break-word" }}
                    ></label>
                    <input
                      className={`text-light text-3 ps-3 custom-border-color-grey-1 custom-placeholder h-auto py-2 w-100 ${
                        errors[input.name] ? "is-invalid" : ""
                      }`}
                      type={input.type}
                      name={input.name}
                      placeholder={input.description}
                      value={formData[input.name]}
                      onChange={handleInputChange}
                    />
                    {errors[input.name] && (
                      <div className="invalid-feedback">
                        {errors[input.name]}
                      </div>
                    )}
                  </div>
                ))}
                <div className="">
                  {errors ? (
                    <div className="text-danger">{errors.error}</div>
                  ) : null}
                  <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-5 mb-3">
                    <Link
                      className="text-decoration custom-text-color custom-fs-smal"
                      to="polisy"
                    >
                      Политика конфиденциальности
                    </Link>
                    <Link
                      className="text-decoration custom-text-color custom-fs-smal"
                      to="terms"
                    >
                      Условия пользования
                    </Link>
                    <Link
                      className="text-decoration custom-text-color custom-fs-smal"
                      to="refund"
                    >
                      Условия возрата
                    </Link>
                  </div>
                  <div className="mb-3">
                    <label className="form-check-label text-white custom-fs d-flex gap-2 align-items-start">
                      <input
                        className="form-check-input "
                        type="checkbox"
                        onClick={() => {
                          setActive(!active);
                        }}
                      />
                      <span className="text-secondary">
                        Я прочитал(а) и согласен(а) с Политикой
                        конфиденциальности и Условиями использования. Я
                        ознакомлен с Политикой возврата.
                      </span>
                    </label>
                  </div>
                  <motion.div
                    variants={textAnimation}
                    custom={4}
                    className="position-relative mb-3"
                  >
                    <button
                      className="text-decoration-none z-1 fw-bold fs-10 position-absolute p-2 text-light border-none me-4 d-flex justify-content-center custom-positio bg-link"
                      type="submit"
                      disabled={active ? false : true}
                    >
                      Войти
                    </button>
                    <span className="d-block custom-io z-0"></span>
                  </motion.div>
                </div>
              </div>
            </form>
            <motion.div
              variants={textAnimation}
              custom={5}
              className="d-flex flex-column flex-lg-row gap-2 gap-lg-5 mb-5"
            >
              <Link
                to="/registration"
                className="text-decoration custom-text-color custom-fs-smal"
              >
                Зарегистрироваться
              </Link>
              <Link
                to="/passwordrecovery"
                className="text-decoration custom-text-color custom-fs-smal"
              >
                Восстановить пароль
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
