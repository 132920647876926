import React from "react";
import HeaderForOzer from "./HeaderForOzer";

const Preparation = () => {
  return (
    <>
      <HeaderForOzer
        title="ПОДГОТОВКА К СДАЧЕ АНАЛИЗА"
        subTitle="Подготовка к сдаче анализа"
      />
      <div className="container mt-5 mb-5 pt-5 pb-5">
        <div className="">
          <div className="fs-10 text-secondary lh-lg">
            <h2 className="font-bold text-black fs-6">
              Общие рекомендации по сдаче анализа крови
            </h2>
            <ul className="fs-10 text-secondary lh-lg">
              <li>
                Старайтесь сдать все анализы за один раз в одной лаборатории,
                чтобы упростить сравнение результатов и наблюдение за динамикой
                изменений показателей.
              </li>
              <li>
                Анализы следует сдавать натощак: 8-12 часов без приема пищи. Это
                требование особенно важно для анализов, таких как анализ крови
                на глюкозу и уровень железа, которые могут потребовать от вас
                воздержания от еды и питья (за исключением воды) в течение 8-10
                часов или даже 12 часов перед тестом.
              </li>
              <li>
                Воздержание от вредных привычек и физических нагрузок: Не
                рекомендуется употреблять алкоголь, заниматься спортом и курить
                накануне сдачи анализов, чтобы избежать искажения результатов.
              </li>
              <li>
                Прием лекарств: Продолжайте прием прописанных вам лекарств, если
                иное не указано вашим врачом. Важно сообщить врачу о всех
                принимаемых лекарствах и добавках, чтобы предотвратить искажение
                результатов анализов.
              </li>
              <li>
                Влияние внешних факторов: Капельницы и внутримышечные инъекции
                накануне могут повлиять на результаты анализов. Также
                рекомендуется в не экстренных ситуациях избегать сдачи анализов
                во время менструации и при ОРВИ.
              </li>
              <li>
                Примечание: Всегда консультируйтесь с вашим врачом или
                лабораторией для получения точных инструкций по подготовке к
                конкретным анализам, так как требования могут отличаться в
                зависимости от типа анализа и вашего индивидуального здоровья.
              </li>
            </ul>
            <p>
              Важно понимать, что значения анализов могут варьироваться в
              зависимости от лаборатории, методов анализа и демографических
              особенностей пациента, таких как возраст и пол. Поэтому, при
              интерпретации результатов анализов важно учитывать лабораторные
              референсные диапазоны и особенности каждого конкретного случая. И
              задача ИИ помочь вам в этом случае.
            </p>
            <p>
              Референсные диапазоны определяются на основании исследования
              большого количества здоровых людей, чтобы установить, что
              считается "нормой" для определенной группы населения. Эти
              диапазоны могут отличаться в зависимости от возраста, пола и
              других факторов, так как, например, уровень креатинина в крови
              зависит от мышечной массы, которая в среднем выше у мужчин, чем у
              женщин.
            </p>
            <p>
              Сандартные и оптимальные значения могут быть различными в
              зависимости от лаборатории, поэтому важно обращать внимание на
              референсные значения, указанные в результатах ваших анализов. Эти
              значения дают представление о том, какие показатели считаются
              нормальными и что может указывать на наличие заболеваний или
              состояний, требующих дальнейшего обследования
            </p>
            <p>
              Результаты, что вам выдает ИИ, учитывает что вы сдали анализы,
              используя общие рекомендации.
            </p>
            <p>
              Общий анализ крови (ОАК) с лейкоцитарной формулой и СОЭ являются
              основными тестами для первичной оценки состояния здоровья
              человека.
            </p>
          </div>
          <div className="fs-10 text-secondary  lh-lg">
            <h2 className="font-bold fs-6 text-black">
              Общий анализ крови с лейкоцитарной формулой
            </h2>
            <ul>
              <li>
                Назначение: ОАК измеряет различные компоненты крови, включая
                эритроциты (которые переносят кислород), лейкоциты (которые
                борются с инфекциями) и тромбоциты (которые помогают крови
                свертываться). Лейкоцитарная формула раскрывает состав белых
                клеток крови, предоставляя информацию о состоянии иммунной
                системы и реакции на патогены или другие стимулы.
              </li>
              <li>
                Показания к назначению: Этот тест часто проводится, если у вас
                есть слабость, утомляемость, лихорадка, частые инфекции,
                необъяснимые кровотечения из носа, синяки без видимой причины
                или проблемы с пищеварением.
              </li>
              <li>
                Интерпретация результатов: Результаты могут указывать на
                различные состояния, от анемии и инфекций до более серьезных
                заболеваний, таких как нарушения в работе костного мозга,
                проблемы с иммунной системой и определенные виды рака.
                Дифференциальный компонент ОАК помогает точно определить причину
                изменений в количестве белых кровяных телец, будь то инфекция,
                воспаление или аллергические реакции.
              </li>
            </ul>
          </div>
          <div className="fs-10 text-secondary lh-lg">
            <h2 className="font-bold fs-6 text-black">
              Скорость оседания эритроцитов {"(СОЭ)"}
            </h2>
            <ul>
              <li>
                Назначение: СОЭ измеряет скорость, с которой эритроциты оседают
                в течение одного часа. Более высокая скорость может указывать на
                воспаление в организме, которое может быть вызвано различными
                состояниями, включая инфекции, рак и аутоиммунные заболевания.
              </li>
              <li>
                Методы измерения: СОЭ может быть определена различными методами,
                включая методы Вестергрена, Винтроба, микро-СОЭ и
                автоматизированные аппараты. Каждый метод имеет свою
                чувствительность и специфичность, причем метод Вестергрена
                является наиболее часто используемым из-за его чувствительности.
              </li>
              <li>
                Факторы, влияющие на результаты: На результаты СОЭ могут влиять
                несколько факторов, включая условия сбора и хранения образца
                крови, физическое состояние образца и специфические факторы
                пациента, такие как размер и форма эритроцитов и характеристики
                плазмы.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preparation;
