import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import PasswordRecovery from "./PasswordRecovery";
import RegistrationPage from "./RegistrationPage";
import LoginPage from "./LoginPage";
import PolisuPage from "./PolisuPage";
import TermsOfUsePage from "./TermsOfUsePage";
import RefundPage from "./RefundPage";
import Contacts from "./Contacts";
import InfoPage from "./InfoPage";
import SimptomsPage from "./SimptomsPage";
import MainPage from "./MainPage";
import ResponseAiPage from "./ResponseAiPage";
import PreparationPage from "./PreparationPage";
import SupplementRecommendationsPage from "./SupplementRecommendationsPage";
import ConsultationWithANutritionistPage from "./ConsultationWithANutritionistPage";

const PublicPage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash && document.querySelector(hash)) {
      setTimeout(function () {
        document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  });
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/passwordrecovery" element={<PasswordRecovery />} />
        <Route path="/polisy" element={<PolisuPage />} />
        <Route path="/terms" element={<TermsOfUsePage />} />
        <Route path="/refund" element={<RefundPage />} />
        <Route path="/hemo" element={<MainPage />} />
        <Route path="/hemoAi" element={<ResponseAiPage />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/blog" element={<InfoPage />} />
        <Route path="/symptoms" element={<SimptomsPage />} />
        <Route path="/page1" element={<PreparationPage />} />
        <Route path="/page2" element={<SupplementRecommendationsPage />} />
        <Route path="/page3" element={<ConsultationWithANutritionistPage />} />
      </Routes>
    </>
  );
};

export default PublicPage;
