import React, { useEffect } from "react";
import Header from "../components/header/Header";

import Registration from "../components/registrationPage/Registration";
import Footer from "../components/footer/Footer";

const RegistrationPage = () => {
  useEffect(() => {
    const element = document.documentElement;
    element.scrollIntoView({ behavior: "instant", block: "start" });
  }, []);
  return (
    <>
      <Header />

      <Registration />
      <Footer />
    </>
  );
};

export default RegistrationPage;
