import React, { useState } from "react";
import Cookies from "js-cookie";
import "./Main.css";
import { useDispatch } from "react-redux";
import { setResponseAi } from "../../store/authSlice";
import { baseUrl } from "../utiles/baseUrl";
import { customAlphabet } from "nanoid";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FormByPdf } from "../forms/FormByPdf";
import { ManualForm } from "../forms/ManualForm";
import { Individual } from "./section/Individual/Individual";

const id = customAlphabet("1234567890", 8);
let numberId = id();
const merchantLogin = process.env.REACT_APP_MERCHANT_LOGIN;
const description = "Техническая документация по ROBOKASSA";
const outSum = 550.0;

const Main = () => {
  const dispatch = useDispatch();
  const Robokassa = window.Robokassa;
  const [showModal, setShowModal] = useState(false);
  const [sucsses, setSucsses] = useState(false);
  const [modal, SetModal] = useState(false);
  const [proverka, setProverka] = useState(false);
  const [ai, setAi] = useState();
  const shpToken = Cookies.get("token");
  const emai = localStorage.getItem("user");
  const email = JSON.parse(emai);
  const [disabled, setDisabled] = useState(false);

  const pay = async () => {
    try {
      const invId = Number(numberId);
      localStorage.setItem("paymentId", JSON.stringify({ INVID: invId }));
      // Start the fetch request and wait for it to complete
      const response = await fetch(`${baseUrl}user/protected_resource`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: shpToken,
        },
        body: JSON.stringify({
          outSum: outSum,
          invId: invId,
          shpToken: shpToken,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const signatureValue = data.signatureValue;
      Robokassa.StartPayment({
        MerchantLogin: merchantLogin,
        OutSum: outSum,
        Email: email.email,
        InvId: invId,
        Description: description,
        Culture: "ru",
        Encoding: "utf-8",
        SignatureValue: signatureValue,
        Shp_token: shpToken,
      });
      setTimeout(() => {
        setShowModal(true);
      }, 8000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //   const [selectedFile, setSelectedFile] = useState(null);

  //   const handleFileSubmit = async (e) => {
  //     e.preventDefault();

  //     // Reset errors
  //     let localErrors = {};

  //     // Ensure a file is selected
  //     if (!selectedFile) {
  //         localErrors.file = "A file is required.";
  //     }

  //     // Check only specific fields from inputType2 are filled
  //     let countFilled = 0;
  //     inputType2.forEach(({ name }) => {
  //         if (formData[name] && formData[name].trim() !== "") {
  //             countFilled++;
  //         }
  //     });

  //     if (countFilled < 2) {
  //         localErrors.inputType2 = "At least two fields must be filled out.";
  //     }

  //     setErrors(localErrors);

  //     if (Object.keys(localErrors).length === 0) {
  //         // Form is valid, proceed with form submission
  // const formDataForSubmission = new FormData();
  // formDataForSubmission.append("file", selectedFile);

  //         // Append only fields from inputType2
  //         inputType2.forEach(({ name }) => {
  //             if (formData[name]) {
  //                 formDataForSubmission.append(name, formData[name]);
  //             }
  //         });

  //         // Perform the fetch operation
  //         try {
  // const response = await fetch(`${baseUrl}process-pdf-olymp`, {
  //     method: "POST",
  //     // Don't set 'Content-Type' for FormData; it's automatically set by the browser
  //     headers: {
  //         Authorization: shpToken,
  //     },
  //     body: formDataForSubmission,
  // });

  // if (!response.ok) throw new Error("Network response was not ok");

  // const data = await response.json();
  // // Handle successful submission
  // console.log("Success:", data);
  //         } catch (error) {
  //             console.error("Error during submission:", error);
  //         }
  //     }
  // };

  const userPayment = () => {
    const token = shpToken;

    const paymentIdString = localStorage.getItem("paymentId");

    // Парсинг строки JSON в объект
    const paymentIdObject = JSON.parse(paymentIdString);
    // Теперь paymentIdObject содержит объект с вашими данными
    const id = paymentIdObject.INVID.toString();
    const invId = {
      InvId: id,
    };

    fetch(`${baseUrl}user/payments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token, // Убедитесь, что 'token' определен корректно
      },
      body: JSON.stringify(invId),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.InvId) {
          dispatch(setResponseAi(ai.response));
          setProverka(true);
          setSucsses(false);
        } else {
          SetModal(true);
          setTimeout(() => SetModal(false), 5000);
        }
      })
      .catch((e) => {
        SetModal(true);
        setTimeout(() => SetModal(false), 5000);
      });
  };

  return (
    <div className="position-relative bg-dark pb-5">
      {modal ? (
        <div className="container position-absolute rounded rounded-3 top-25 end-0 w-25 h">
          Оплатите чтобы продолжить
        </div>
      ) : null}

      <div className="container-fluid bg-dark text-light">
        <main className="container pt-5">
          <Tabs pt={5} isFitted variant="enclosed">
            <TabList>
              <Tab
                width="100%"
                fontSize={{ base: 15, lg: 24 }}
                p={4}
                _selected={{
                  color: "brand.accent",
                  borderColor: "brand.accent",
                  bg: "transparent",
                  fontSize: { base: 15, lg: 24 },
                }}
              >
                Расшифровка анализов
              </Tab>
              <Tab
                width="100%"
                fontSize={{ base: 15, lg: 24 }}
                p={4}
                _selected={{
                  color: "brand.accent",
                  borderColor: "brand.accent",
                  bg: "transparent",
                  fontSize: { base: 15, lg: 24 },
                }}
              >
                Индивидуальные рекомендации
              </Tab>
            </TabList>

            <TabPanels sx={{ mb: "60px" }}>
              <TabPanel>
                <div
                  data-aos="fade"
                  data-aos-delay={200}
                  data-aos-anchor-placement="null"
                  data-aos-once="false"
                >
                  <div className="d-flex flex-column align-items-center pt-5">
                    <span className="custom-letter custom-text-color">
                      Общий Анализ Крови
                    </span>
                    <p className="fs-5 font-bold w-80 pt-3 text-center">
                      Заполните ваши медицинские данные внизу, чтобы получить
                      расшифровку от Искусственного Интеллекта
                    </p>
                    <p className="fs-7 w-70 text-center">
                      Ваши медицинские данные вы можете предоставить двумя
                      способами: отправить файл в .pdf формате (в работе) или
                      заполнить вручную.
                    </p>
                  </div>

                  <Tabs pt={5}>
                    <TabList>
                      <Tab
                        width="100%"
                        p={4}
                        _selected={{
                          color: "brand.accent",
                          borderColor: "brand.accent",
                          bg: "transparent",
                        }}
                      >
                        Отправить файл PDF
                      </Tab>
                      <Tab
                        width="100%"
                        p={4}
                        _selected={{
                          color: "brand.accent",
                          borderColor: "brand.accent",
                          bg: "transparent",
                        }}
                      >
                        Заполнить вручную
                      </Tab>
                    </TabList>

                    <TabPanels sx={{ mb: "60px" }}>
                      <TabPanel>
                        <FormByPdf
                          userPayment={userPayment}
                          sucsses={sucsses}
                          proverka={proverka}
                          disabled={disabled}
                          showModal={showModal}
                          setDisabled={setDisabled}
                          setShowModal={setShowModal}
                          setProverka={setProverka}
                          setSucsses={setSucsses}
                          setAi={setAi}
                          pay={pay}
                        />
                      </TabPanel>
                      <TabPanel>
                        <ManualForm
                          userPayment={userPayment}
                          sucsses={sucsses}
                          proverka={proverka}
                          disabled={disabled}
                          showModal={showModal}
                          setDisabled={setDisabled}
                          setShowModal={setShowModal}
                          setProverka={setProverka}
                          setSucsses={setSucsses}
                          setAi={setAi}
                          pay={pay}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </div>
              </TabPanel>
              <TabPanel>
                <Individual />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </main>
      </div>
    </div>
  );
};

export default Main;
