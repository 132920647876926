import React from "react";
import HederForOzer from "./HeaderForOzer";
import { Link } from "react-router-dom";

const Info = () => {
  return (
    <>
      <HederForOzer
        title="ПОЛЕЗНАЯ ИНФОРМАЦИЯ"
        subTitle="Полезная информация"
      />
      <div className="mb-5 pb-5">
        <div className="container">
          <p className="text-secondary text-center fs-10 lh-lg">
            Здесь вы сможете найти полезную информацию для вашего здоровья.
          </p>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between gap-3 container mt-5 mb-5">
          <Link className="section-custom-width text-decoration">
            <div>
              <div className="d-flex gap-2 position-relative custom-width-heigth">
                <div className="position-relative none-logo"></div>
                <span className="pt-4 ps-4 h-100 w-100 position-absolute d-flex flex-column-reverse align-items-end">
                  <div className="position-relative mb-3">
                    <button
                      className="text-decoration-none z-1 custom-none fw-bold fs-10 position-absolute  text-light border-none me-4 d-flex flex-column pt-2 align-items-center bg-link"
                      type="submit"
                    >
                      <span>18</span>
                      МАР
                    </button>
                    <span className="d-block custom-ioa bg-secondary z-0"></span>
                  </div>
                </span>
              </div>
              <span className="d-block ms-4 mt-5 fs-10 text-secondary">
                ЖКТ
              </span>
              <h3 className="fs-5 text-bold ms-4 text-black">
                ЖКТ
              </h3>
              <Link className=" fs-10 custom-text-color text-decoration ms-4 mb-5 me-4 d-flex justify-content-between align-items-center">
                View More
                <svg
                  height="32"
                  viewBox="0 0 32 32"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path d="m0 0h32v32h-32z" />
                    <path
                      d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
                      fill="#f26947"
                      transform="matrix(-1 0 0 -1 32.04264 31.985282)"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          </Link>
          <Link className="section-custom-width text-decoration">
            <div>
              <div className="d-flex gap-2 position-relative custom-width-heigth">
                <div className="position-relative none-logo"></div>
                <span className="pt-4 ps-4 h-100 w-100 position-absolute d-flex flex-column-reverse align-items-end">
                  <div className="position-relative ms-3 mb-3 ">
                    <button
                      className="text-decoration-none z-1 custom-none fw-bold fs-10 position-absolute  text-light border-none me-4 d-flex flex-column pt-2 align-items-center bg-link"
                      type="submit"
                    >
                      <span>15</span>
                      ФЕВ
                    </button>
                    <span className="d-block custom-ioa bg-secondary z-0"></span>
                  </div>
                </span>
              </div>
              <span className="d-block ms-4 mt-5 fs-10 text-secondary">
                Щитовидная Железа
              </span>
              <h3 className="fs-5 text-bold ms-4 text-black">
                Щитовидная Железа
              </h3>
              <Link className=" fs-10 custom-text-color text-decoration ms-4 mb-5 me-4 d-flex justify-content-between align-items-center">
                View More
                <svg
                  height="32"
                  viewBox="0 0 32 32"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path d="m0 0h32v32h-32z" />
                    <path
                      d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
                      fill="#f26947"
                      transform="matrix(-1 0 0 -1 32.04264 31.985282)"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          </Link>
          <Link className="section-custom-width text-decoration">
            <div>
              <div className="d-flex gap-2 position-relative custom-width-heigth">
                <div className="position-relative none-logo"></div>
                <span className="pt-4 ps-4 h-100 w-100 position-absolute d-flex flex-column-reverse align-items-end">
                  <div className="position-relative mb-3">
                    <button
                      className="text-decoration-none z-1 custom-none fw-bold fs-10 position-absolute  text-light border-none me-4 d-flex flex-column pt-2 align-items-center bg-link"
                      type="submit"
                    >
                      <span>12</span>
                      ФЕВ
                    </button>
                    <span className="d-block custom-ioa bg-secondary z-0"></span>
                  </div>
                </span>
              </div>
              <span className="d-block ms-4 mt-5 fs-10 text-secondary">
                Всё о желчи
              </span>
              <h3 className="fs-5 text-bold ms-4 text-black">
                Всё о желчи
              </h3>
              <Link className=" fs-10 custom-text-color text-decoration ms-4 mb-5 me-4 d-flex justify-content-between align-items-center">
                View More
                <svg
                  height="32"
                  viewBox="0 0 32 32"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path d="m0 0h32v32h-32z" />
                    <path
                      d="m9.88528137 7.48578644 1.41421353 1.41421356-6.0994949 6.0997864 25.4426407.0002136v2l-25.4286407-.0002136 6.0854949 6.085495-1.41421353 1.4142135-8.48528137-8.4852813.022-.0214272-.022-.0217186z"
                      fill="#f26947"
                      transform="matrix(-1 0 0 -1 32.04264 31.985282)"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Info;
