import React, { useState } from "react";
import "./ResponseAi.css";
import Cookies from "js-cookie";
import { Typewriter } from "react-simple-typewriter";
import { useSelector } from "react-redux";
import { baseUrl } from "../utiles/baseUrl";

const ResponsAi = () => {
  const ai_response = useSelector((state) => state.auth.responseAi);
  const [like, setLike] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [feedback, setFitback] = useState();
  const token = Cookies.get("token");
  let combinedWordsWithPause;
  const formData = {
    like,
    feedback,
  };
  localStorage.removeItem("paymentId");
  if (ai_response) {
    combinedWordsWithPause = ai_response.join("\n\n");
  }
  const handleChange = (e) => {
    setFitback(e.target.value);
  };

  const handleClick = () => {
    setLike(!like);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!feedback) {
      return;
    } else {
      fetch(`${baseUrl}user/save-user-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token, // Assuming the token is directly the value without "Bearer"
        },
        body: JSON.stringify(formData),
      }).then(() => {
        setDisabled(false);
      });
    }
  };

  return (
    <>
      <div className="bg-dark pb-xl-5">
        <div className="container text-light">
          <div className="d-flex flex-column  gap-md-5 flex-xl-row justify-content-xl-around">
            <div className="custom-width-login">

              <span className="d-block pt-4 pb-2 custom-text-color">
                ИИ Адель
              </span>

              <div className="fs-6 bg-dark text-wrap mb-3 d-flex flex-column justify-content-between text-break bg-light rounded p-4 fs-5 border overflow" style={{ height: '500px', overflowY: 'auto' }}>
                <div className="text-secondary">
                  <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    <Typewriter
                      words={[combinedWordsWithPause]}
                      typeSpeed={10}
                      deleteSpeed={10}
                      delaySpeed={1500000}
                      loop={0}
                      cursor={false}
                    />
                  </pre>
                </div>
                <div className="w-100 d-flex justify-content-end">
                  <button
                    onClick={handleClick}
                    className="rounded-5 width-for-like btn btn-outline-light d-flex justify-content-center border-none align-items-center me-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill={like ? "red" : "white"}
                      width="30px"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="black"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                      />
                    </svg>
                  </button>
                  <span className="d-block custom-text-color mb-2 pt-lg-4">
                    Жми на сердечко и отправь Нам отзыв
                  </span>
                </div>

              </div>
              <span className="d-block custom-text-color mb-3 pt-lg-4 p-3">
                Вы можете скопировать расшифровку или написать нам, и мы отправим её вам на почту.
              </span>
            </div>
            <div className="pt-xl-4">
              <span className="d-block text-center custom-text-color mb-2 pt-lg-4 p-1">
                Здесь вы можете оставить любые комментарии или отправить отзыв
              </span>
              <div className="">
                <div className="pb-3">
                  <span className="d-block custom-text-color pt-3">Отзыв</span>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="d-flex flex-column align-items-end gap-4"
                >
                  <div className="w-100 d-flex justify-content-end">
                    <textarea
                      onChange={handleChange}
                      value={feedback}
                      className="w-100 bg-dark border rounded text-light resize custom-media p-2"
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-end w-25">
                    {ai_response ? (
                      <button
                        disabled={!disabled}
                        className="custom-bg custom-hover-ai text-light text-center p-1 ps-1 pe-4 diseb"
                        type="submit"
                      >
                        Отправить
                      </button>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponsAi;
