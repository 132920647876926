import React from "react";
import Slider from "react-slick";
import alua1 from "./team/ai.jpeg";
import dana2 from "./team/dana.jpeg";
import adel4 from "./team/docpng.jpg";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    y: 150,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const initialSlide = [
  { 
    name: "Алуа Мусралина", 
    info: (
      <>
        CEO & ML/AI Engineer
        <ul>
          <li>10 лет опыта работы в науке, разработке и продажах</li>
          (Daimler, AUDI, KIT)
          <li>Выпускница МФТИ, Россия</li>
          <li>Выпускница CAU, Германия</li>
        </ul>
      </>
    ), 
    img: alua1 
  },
  { 
    name: "Дана", 
    info: (
      <>
       MD
        <ul>
          <li>12 лет опыта работы в медицине (Lilly, Johnson&Johnson)</li>
          <li>Практикующий врач в Финляндии</li>
          <li>Выпускница КазНМУ им. Асфендиярова, Казахстан</li>
        </ul>
      </>
    ), 
    img: dana2 
  },
  { 
    name: "Адель", 
    info: (
      <>
       Искусственный Интеллект
        <ul>
          <li>8000+ медицинских экспертов в одной умной системе</li>
          <li>Система без галлюцинаций</li>
          <li>99.9% точности</li>
        </ul>
      </>
    ), 
    img: adel4 
  },
  
];




const Team = () => {
  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
      id="team"
      className="bg-for-team slider-container pb-5"
    >
      <div className="reverse-img bg-for-team position-relative">
        <svg
          className="position-absolute positions-svg w-100"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 102"
          preserveAspectRatio="xMinYMin"
        >
          <polygon
            fill="#FFFFFF"
            points="0,65 220,35 562,63 931,10 1410,68 1920,16 1920,103 0,103 "
          ></polygon>
        </svg>
      </div>
      <div className="text-center pt-5">
        <motion.span
          variants={textAnimation}
          custom={1}
          className="custom-letter custom-text-color font-bold"
        >
          КОМАНДА
        </motion.span>
        <motion.h2
          variants={textAnimation}
          custom={2}
          className="fs-3 font-bold  p-lg-3"
        >
          Наша команда профессионалов
        </motion.h2>
      </div>
      <motion.div variants={textAnimation} custom={3}>
        <Slider {...settings}>
          {initialSlide.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex gap-2 justify-content-center align-items-center mb-4 ower"
              >
                <div className="position-relative width-slider custom-shadow">
                  <img className="w-100 pb-5" src={item.img} alt={item.info} />
                  <div className="position-absolute custom-position-img bg-light w-100 ps-4  pt-4">
                    <p className="fs-4 font-bold lh-sm">
                      {item.name}
                      <br />
                      <span className="fs-6 text-secondary">{item.info}</span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </motion.div>
    </motion.div>
  );
};

export default Team;
