import React from "react";

const Policy = () => {
  return (
    <div className="container">
      <h2 className="font-bold fs-6 mb-3">
        Политика Конфиденциальности сервиса Hemo-AI (ИП LoanaAI)
      </h2>
      <p className="fs-10 text-secondary lh-lg">
        Эта страница служит для информирования посетителей о наших политиках в
        отношении сбора, использования и раскрытия Личной Информации, если
        кто-либо решил использовать наш Сервис.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Если вы решите использовать наш Сервис, то вы соглашаетесь на сбор и
        использование информации в соответствии с этой политикой. Личная
        информация, которую мы собираем, используется для предоставления и
        улучшения Сервиса. Мы не будем использовать или делиться вашей
        информацией с кем-либо, кроме как описано в этой Политике
        Конфиденциальности.
      </p>
      <p className="fs-10 text-secondary lh-lg">
        Термины, используемые в этой Политике Конфиденциальности, имеют те же
        значения, что и в наших Условиях и Положениях, которые доступны на сайте
        Hemo-AI, если иное не определено в этой Политике Конфиденциальности.
      </p>

      <div>
        <h3 className="font-bold fs-6 mb-3">Сбор и Использование Информации</h3>
        <p className="fs-10 text-secondary lh-lg">
          Для лучшего опыта при использовании нашего Сервиса, мы можем
          потребовать от вас предоставления определенной личной информации,
          включая, но не ограничиваясь, адресами электронной почты, деталями,
          отправленными через формы, загруженными файлами, ответами,
          сгенерированными ИИ, и отзывами клиентов. Информация, которую мы
          запрашиваем, будет сохранена нами и использована как описано в этой
          политике конфиденциальности.
        </p>
        <p className="fs-10 text-secondary lh-lg">
          Приложение может использовать сторонние сервисы, которые могут
          собирать информацию, используемую для вашей идентификации. Мы хотим
          информировать вас о том, что всякий раз, когда вы используете наш
          Сервис, в случае ошибки в приложении мы собираем данные и информацию
          (через продукты третьих сторон) на вашем телефоне, называемые Данными
          Журнала. Эти Данные Журнала могут включать такую информацию, как ваш
          Интернет-протокол (IP), название устройства, версия операционной
          системы, конфигурация приложения при использовании нашего Сервиса,
          время и дата использования Сервиса, а также другие статистические
          данные.
        </p>
      </div>
      <div>
        <h4 className="font-bold fs-6 mb-3">Cookies</h4>
        <p className="fs-10 text-secondary lh-lg">
          Cookies – это файлы с небольшим объемом данных, которые обычно
          используются как анонимные уникальные идентификаторы. Они отправляются
          в ваш браузер с веб-сайтов, которые вы посещаете, и хранятся во
          внутренней памяти вашего устройства. Этот Сервис явно не использует
          эти "cookies". Тем не менее, приложение может использовать сторонний
          код и библиотеки, которые используют "cookies" для сбора информации и
          улучшения своих сервисов. У вас есть возможность либо принять, либо
          отказаться от этих cookies и знать, когда cookie отправляется на ваше
          устройство. Если вы решите отказаться от наших cookies, вы можете не
          иметь возможности использовать некоторые части этого Сервиса.
        </p>
      </div>
      <div>
        <h4 className="font-bold fs-6 mb-3">Поставщики Услуг</h4>
        <p className="fs-10 text-secondary lh-lg">
          Мы можем нанимать сторонние компании и индивидуумов по следующим
          причинам:
          <ul>
            <li>Для облегчения нашего Сервиса;</li>
            <li>Для предоставления Сервиса от нашего имени;</li>
            <li>Для выполнения услуг, связанных с Сервисом; или</li>
            <li>Для помощи нам в анализе использования нашего Сервиса.</li>
          </ul>
          Мы хотим информировать пользователей этого Сервиса о том, что эти
          третьи стороны имеют доступ к их Личной Информации. Причина в том,
          чтобы выполнять задачи, назначенные им от нашего имени. Однако, они
          обязаны не раскрывать или использовать информацию для каких-либо
          других целей.
        </p>
      </div>
      <div>
        <h4 className="font-bold fs-6 mb-3">Безопасность</h4>
        <p className="fs-10 text-secondary lh-lg">
          Мы ценим ваше доверие в предоставлении нам вашей Личной Информации,
          поэтому мы стремимся использовать коммерчески приемлемые средства
          защиты. Но помните, что ни один метод передачи данных через интернет,
          или метод электронного хранения данных не является на 100% безопасным
          и надежным, и мы не можем гарантировать его абсолютную безопасность.
        </p>
      </div>
      <div>
        <h4 className="font-bold fs-6 mb-3">Ссылки на Другие Сайты</h4>
        <p className="fs-10 text-secondary lh-lg">
          Этот Сервис может содержать ссылки на другие сайты. Если вы нажмете на
          ссылку третьей стороны, вы будете направлены на этот сайт. Обратите
          внимание, что эти внешние сайты не управляются нами. Поэтому мы
          настоятельно рекомендуем вам ознакомиться с Политикой
          Конфиденциальности этих веб-сайтов. У нас нет контроля над
          содержанием, политиками конфиденциальности или практиками сторонних
          сайтов или услуг.
        </p>
        <p className="fs-10 text-secondary lh-lg">
          Изменения в Этой Политике Конфиденциальности
        </p>
        <p className="fs-10 text-secondary lh-lg">
          Мы можем обновлять нашу Политику Конфиденциальности время от времени.
          Поэтому вам рекомендуется периодически просматривать эту страницу на
          предмет изменений. Мы уведомим вас о любых изменениях, опубликовав
          новую Политику Конфиденциальности на этой странице.
        </p>
        <p className="fs-10 text-secondary lh-lg">
          Эта политика вступает в силу с 1 апреля 2024 года.
        </p>
      </div>
      <div>
        <h4 className="font-bold fs-6 mb-3">Связаться с Нами</h4>
        <p className="fs-10 text-secondary lh-lg">
          Если у вас есть какие-либо вопросы или предложения относительно нашей
          Политики Конфиденциальности, не стесняйтесь обращаться к нам по адресу{" "}
          <br />
          <a
            className="text-decoration custom-text-color font-bold"
            href="mailto:info@hemo-ai.kz"
          >
            admin@hemo-ai.kz.
          </a>
        </p>
      </div>
    </div>
  );
};

export default Policy;
