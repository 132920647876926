import React from "react";
import HeaderForOzer from "./HeaderForOzer";

const SupplementRecommendations = () => {
  return (
    <>
      <HeaderForOzer
        title="ПОЛУЧИТЬ ОБЩИЕ РЕКОМЕНДАЦИИ ПО ВИТАМИНАМ"
        subTitle="Получить общие рекомендации по витаминам и добавкам"
      />
      <div className="container mt-5 pt-5 mb-5 pb-5 fs-10 text-secondary lh-lg">
        Напишите нам по номеру +7 707 9245615 или на почту info@hemo-ai.kz.
      </div>
    </>
  );
};

export default SupplementRecommendations;
