import React from "react";
import galka from "./img/galka.svg";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    y: 200,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const Advantages = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      className="custom-gradient-reverse"
    >
      <div className="reverse-img custom-gradient position-relative">
        <svg
          className="position-absolute positions-svg w-100"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 102"
          preserveAspectRatio="xMinYMin"
        >
          <polygon
            fill="#FFFFFF"
            points="0,65 220,35 562,63 931,10 1410,68 1920,16 1920,103 0,103 "
          ></polygon>
        </svg>
      </div>
      <div className="container d-flex justify-content-around align-items-center pt-5 pb-5">
        <div className="pt-5 pb-5">
          <h2 className="font-bold text-light line-height-2 animate-text d-none d-lg-block custom-bg-text">
            Преимущества
          </h2>
        </div>
        <div className="pt-5 pb-5 custom-width-login animate-text">
          <motion.span
            custom={1}
            variants={textAnimation}
            className="custom-letter custom-text-color"
          >
            ПОЧЕМУ МЫ
          </motion.span>
          <motion.h3
            custom={2}
            variants={textAnimation}
            className="fs-4 mt-2 text-light pt-3 pb-3"
          >
            Узнайте о главных причинах почему нужно выбрать именно нас
          </motion.h3>
          <ul className="list-type text-secondary d-flex flex-column gap-3">
            <motion.li
              custom={3}
              variants={textAnimation}
              className="d-flex align-items-center gap-2"
            >
              <img src={galka} alt="galka" />
              <span>
                Улучшается ваше лечение за счёт отслеживания динамики ваших
                показателей.
              </span>
            </motion.li>
            <motion.li
              custom={4}
              variants={textAnimation}
              className="d-flex align-items-center gap-2"
            >
              <img src={galka} alt="galka" />
              <span>
                Получаете предварительую диагностику и второе мнение.
                Получаете информацию от ИИ, которая позволит вашему врачу
                принимать более быстрые и точные диагностические решения.
              </span>
            </motion.li>
            <motion.li
              custom={5}
              variants={textAnimation}
              className="d-flex align-items-center gap-2"
            >
              <img src={galka} alt="galka" />
              <span>
              Получаете рекомендациями по диете и витаминам.
              </span>
            </motion.li>
            
            <motion.li
              custom={6}
              variants={textAnimation}
              className="d-flex align-items-center gap-2"
            >
              <img src={galka} alt="galka" />
              <span>
                Значительно сократите время постановки окончательного диагноза,
                тем самым снизите стоимость лечения.
              </span>
            </motion.li>
          </ul>
        </div>
      </div>
    </motion.section>
  );
};

export default Advantages;
