import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
import img1 from "./img/about-1.jpeg";
import img2 from "./img/about-2.jpeg";
import img3 from "./img/about-3.jpeg";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    y: 200,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const About = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      id="abouts"
      className="d-flex flex-column flex-lg-row align-items-center gap-5 container pb-5"
    >
      <div className="pt-lg-5">
        <div className="d-flex align-items-center gap-2 pt-5 mt-lg-5">
          <div className="custom-about">
            <img className="w-100" src={img1} alt="Logo" />
          </div>
          <div className="d-flex flex-column gap-3 ">
            <img className="custom-about-2" src={img2} alt="Logo" />
            <img
              className="custom-about-2 pt-md-0 mt-md-1"
              src={img3}
              alt="Logo"
            />
          </div>
        </div>
      </div>
      <div className="custom-width-login mt-lg-5">
        <motion.span
          custom={1}
          variants={textAnimation}
          className="custom-letter custom-text-color font-bold"
        >
          О НАС
        </motion.span>
        <motion.p
          custom={2}
          variants={textAnimation}
          className="font-bold fs-5 lh-sm"
        >
          Наша цель - помочь вам определить потенциальные заболевания или
          возможные дефициты в организме
        </motion.p>
        <motion.p
          custom={3}
          variants={textAnimation}
          className="fs-10  text-secondary"
        >
          Вся информация предоставляется вам из большой базы данных с помощью
          искусственного интеллекта.
          <br /> База данных подвергается тщательной проверке.
          <br /> Эта информация предназначена только в образовательных целях или для второго мнения и
          не должна использоваться в качестве замены профессиональной
          медицинской консультации, диагностики или лечения.
        </motion.p>
        <motion.p
          custom={4}
          variants={textAnimation}
          className="font-bold fs-5 lh-sm"
        >
          Как работает наш сервис?
        </motion.p>
        <motion.p
          custom={5}
          variants={textAnimation}
          className="fs-10  text-secondary"
        >
          Сначала вы регистрируетесь, предоставляете свои медицинские данные,
          после оплаты Искусственный Интеллект анализирует их и выдает
          результаты о потенциальных заболеваниях и возможных дефицитах,
          сверяясь с доказанными источниками. Это должно улучшить точность
          диагностики и повысить вероятность успешного лечения.
        </motion.p>
        <motion.div
          custom={6}
          variants={textAnimation}
          className="position-relative"
        >
          {isAuthorized ? null : (
            <>
              <Link
                className="text-decoration-none fw-bold fs-10 position-absolute p-3 text-light  me-4 d-flex justify-content-center custom-positio bg-link"
                to="/#login"
              >
                Зарегистрироваться
              </Link>
              <span className="d-block custom-bottom "></span>
            </>
          )}
        </motion.div>
      </div>
    </motion.section>
  );
};

export default About;
