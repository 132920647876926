import React, { useState } from "react";
import HeaderTop from "./HeaderTop";
import "./Header.css";
import { useLocation } from "react-router-dom";
import HeaderBottom from "./HeaderBottom";

const navbarItem = [
  { text: "Главная", to: "/" },
  { text: "О нас", to: "/#abouts" },
  { text: "Сервисы", to: "/#servis" },
  { text: "Симптомы", to: "/symptoms" },
  { text: "Команда", to: "/#team" },
  { text: "Полезная информация", to: "/blog" },
];

const Header = () => {
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  const showModal = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <>
      <header className="mb-5 bg-light position-relative">
        <HeaderTop />
        <HeaderBottom
          openMenu={openMenu}
          pathname={pathname}
          showModal={showModal}
          navbarItem={navbarItem}
        />
      </header>
    </>
  );
};

export default Header;
