import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Main from "../components/mainPage/Main";
import { useSelector } from "react-redux";

const MainPage = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  useEffect(() => {
    const element = document.documentElement;
    element.scrollIntoView({ behavior: "instant", block: "start" });
  }, []);
  return (
    <>
      {isAuthorized ? (
        <>
          <Header />
          <Main />
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default MainPage;
