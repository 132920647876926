import React from "react";
import "./HeaderForOther.css";
import { Link } from "react-router-dom";

const HeaderForOzer = ({ title, subTitle }) => {
  return (
    <div className="bg-header-other pt-5">
      <div className="container pb-lg-4">
        <div className="pb-lg-4">
          <ul className="list-type d-flex gap-1 justify-content-center align-items-center">
            <li>
              <Link className="text-decoration custom-color " to="/">
                ГЛАВНАЯ
              </Link>
            </li>
            <li className="text-body-tertiary custom-padding"> {">"} </li>
            <li className="text-secondary font-size-other custom-padding">
              {title}
            </li>
          </ul>
          <h2 className="font-bold fs-2 text-center pb-5">{subTitle}</h2>
        </div>
      </div>
      <div className="bg-header-other position-relative pb-4">
        <svg
          className="position-absolute positions-svg w-100"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 102"
          preserveAspectRatio="xMinYMin"
        >
          <polygon
            fill="#FFFFFF"
            points="0,65 220,35 562,63 931,10 1410,68 1920,16 1920,103 0,103 "
          ></polygon>
        </svg>
      </div>
    </div>
  );
};

export default HeaderForOzer;
