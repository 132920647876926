import * as yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { baseUrl } from "../utiles/baseUrl";
import { useNavigate } from "react-router-dom";
import Loader from "../mainPage/Loader";

const validationSchema = yup.object({
  age: yup.number().required("Обязательное поле"),
  gender: yup.string().required("Обязательное поле"),
  file: yup
    .mixed()
    .required("Выберите файл")
    .test("fileType", "Недопустимый тип файла", (value) => {
      if (!value) return false; // Проверка, что файл был выбран
      return value && value.type === "application/pdf"; // Проверка типа файла на PDF
    })
    .test("fileSize", "Размер файла слишком большой", (value) => {
      if (!value) return false; // Проверка, что файл был выбран
      return value && value.size <= 10485760; // Проверка размера файла (в данном случае до 10 МБ)
    }),
});

const dropzoneStyles = {
  base: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: "md",
    borderStyle: "dashed",
    backgroundColor: "transparent",
    color: "#718096",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  active: {
    borderColor: "#38A169",
  },
  reject: {
    borderColor: "#E53E3E",
  },
};

export const FormByPdf = ({
  pay,
  userPayment,
  sucsses,
  disabled,
  proverka,
  showModal,
  setShowModal,
  setSucsses,
  setProverka,
  setDisabled,
  setAi,
}) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      age: "",
      gender: "",
      file: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldValue }) => {
      setFieldValue("loading", true);
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("age", String(values.age));
      formData.append("gender", values.gender);

      try {
        setDisabled(true);
        pay();
        const response = await fetch(`${baseUrl}process-pdf-olymp`, {
          method: "POST",
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //     Authorization: shpToken,
          //   },
          body: formData,
        });

        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setAi(data);
        if (data.response) {
          setSucsses(true);
          setDisabled(false);
        }
        // Handle successful submission
        console.log("Success:", data);
        resetFormikState();
      } catch (error) {
        console.log(error.message);
      } finally {
        setFieldValue("loading", false);
      }
    },
  });
  const resetFormikState = () => {
    formik.resetForm();
    formik.setStatus({});
    formik.setErrors({});
    formik.setSubmitting(false);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: "application/pdf",
      maxFiles: 1,
      onDrop: () => {
        formik.setFieldTouched("file", true);
      },
      onDropRejected: (rejectedFiles) => {
        const rejectedFile = rejectedFiles[0];
        if (rejectedFile && rejectedFile.file.type !== "application/pdf") {
          formik.setFieldError(
            "file",
            "Недопустимый тип файла. Пожалуйста, выберите файл в формате PDF."
          );
        } else if (rejectedFile && rejectedFile.file.size > 10485760) {
          formik.setFieldError(
            "file",
            "Размер файла слишком большой. Пожалуйста, выберите файл размером до 10 МБ."
          );
        }
      },
      onDropAccepted: (acceptedFiles) => {
        const selectedFile = acceptedFiles[0];
        const type = selectedFile.type;
        if (type === "application/pdf") {
          formik.setFieldValue("file", selectedFile);
          formik.setFieldError("file", null);
        } else {
          formik.setFieldValue("file", {});
        }
      },
    });

  return (
    <div className="pt-2">
      <form
        className="position-relative"
        style={{ maxWidth: 800, margin: "0 auto" }}
        onSubmit={formik.handleSubmit}
      >
        <div className="row p-0 d-xg-flex justify-content-center gap-lg-5 pt-3">
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            justifyContent="center"
            gap={8}
            mb={{ base: 10, lg: 0 }}
          >
            <GridItem w="100%">
              <FormControl
                isRequired
                isInvalid={formik.touched.age && formik.errors.age}
              >
                <FormLabel
                  color={
                    formik.touched.age && formik.errors.age
                      ? "red.500"
                      : "inherit"
                  }
                >
                  Возраст
                </FormLabel>
                <Input
                  _focus={{
                    borderColor: "brand.accent",
                    boxShadow: "0 0 0 1px rgba(240, 76, 35, 0.6)",
                  }}
                  placeholder="Введите возраст"
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="age"
                  type="number"
                />
                {formik.touched.age && formik.errors.age && (
                  <FormErrorMessage>{formik.errors.age}</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem w="100%">
              <FormControl
                isRequired
                isInvalid={formik.touched.gender && formik.errors.gender}
              >
                <FormLabel
                  color={
                    formik.touched.gender && formik.errors.gender
                      ? "red.500"
                      : "inherit"
                  }
                >
                  Пол
                </FormLabel>
                <Select
                  placeholder="Выберите пол (муж/жен)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="gender"
                  value={formik.values.gender}
                >
                  <option value="муж">Мужской</option>
                  <option value="жен">Женский</option>
                </Select>
                {formik.touched.gender && formik.errors.gender && (
                  <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: 2,
            }}
          >
            <FormControl
              className="w-100"
              isInvalid={formik.touched.file && formik.errors.file}
            >
              <div
                {...getRootProps({ className: "dropzone" })}
                style={
                  isDragActive
                    ? dropzoneStyles.active
                    : isDragReject
                    ? dropzoneStyles.reject
                    : dropzoneStyles.base
                }
              >
                <input {...getInputProps()} />
                <p className="m-0">
                  Перетащите файл сюда или кликните, чтобы выбрать файл.
                </p>
              </div>
              {formik.touched.file && formik.errors.file && (
                <FormErrorMessage>{formik.errors.file}</FormErrorMessage>
              )}

              <aside
                className="pt-4"
                style={{
                  opacity: formik.values.file?.path ? "1" : "0",
                  height: 85,
                }}
              >
                <Text fontSize="lg">Выбранный Файл</Text>
                <ul>
                  <li>
                    <Text fontSize="md" color="brand.accent">
                      {formik.values.file?.name}
                    </Text>
                  </li>
                </ul>
              </aside>
            </FormControl>
            {showModal ? (
              <div className="container position-absolute z-3 rounded rounded-5 top-0 bottom-0 end-0 start-0  w-100 h-100">
                <div className="w-50 position-fixed top-50 start-50 translate-middl translate-middle bg-light p-3 rounded">
                  <button
                    className="position-absolute top-0 btn btn-light end-0 m-3"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(!showModal);
                      setSucsses(false);
                      setProverka(false);
                      setDisabled(false);
                    }}
                  >
                    <svg
                      width="30px"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  {sucsses ? (
                    <p className="text_success text-center fs-4 fw-bold paddingTop d-flex align-items-center row-gap-4 flex-column">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          userPayment();
                        }}
                        className="btn btn-secondary w-70 mt-2 text-center "
                      >
                        Просмотреть результат
                      </button>
                    </p>
                  ) : (
                    <div className="d-flex flex-column align-items-center pb-3">
                      {disabled ? (
                        <>
                          <p className="text-black fs-4 text-center fw-bold paddingTop responsive-text">
                            Пожалуйста подождите, пока мы обрабатываем ваш
                            запрос
                          </p>
                          <Loader />
                        </>
                      ) : (
                        <>
                          <p
                            className={`${
                              proverka ? "text-black " : "text-black "
                            } fs-4 fw-bold paddingTop text-center responsive-text`}
                          >
                            {proverka
                              ? "Спасибо оплата прошла успешно"
                              : "Проверьте все поля перед отправкой"}
                          </p>
                          <button
                            className="custom-bg custom-hover-ai border-none text-light text-center p-2 ps-4 pe-3 w-20 diseb"
                            onClick={() =>
                              proverka ? navigate("/hemoai") : null
                            }
                            type={proverka ? "" : "submit"}
                          >
                            {proverka
                              ? "Просмотреть результат"
                              : "Всё верно. Отправить."}
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <div className="position-relative mb-3 mt-4">
              {/* <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(!showModal);
                }}
                className="text-decoration-none  border-none fw-bold fs-10 position-absolute p-2 text-light  me-4 d-flex justify-content-center custom-positio bg-link "
              >
                Отправить
              </button> 
              <span className="d-block custom-io bg-secondary"></span>
              */}

              {/* Заблокированная кнопка */}
              <button
                disabled
                className="text-decoration-none  border-none fw-bold fs-10 p-2 text-light  me-4 d-flex justify-content-center custom-positio bg-link bg-secondary"
              >
                Отправить
              </button>
            </div>
          </Box>
          <div className="col-md-5 responsive-text"></div>
        </div>
        {/* {showModal ? (
          <div className="container position-absolute z-3 rounded rounded-5 top-0 bottom-0 end-0 start-0  w-100 h-100">
            <div className="w-50 position-fixed top-50 start-50 translate-middl translate-middle bg-light p-3 rounded">
              <button
                className="position-absolute top-0 btn btn-light end-0 m-3"
                onClick={() => {
                  setShowModal(!showModal);
                  setSucsses(false);
                  setProverka(false);
                  setDisabled(false);
                }}
              >
                <svg
                  width="30px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
              {sucsses ? (
                <p className="text_success text-center fs-4 fw-bold paddingTop d-flex align-items-center row-gap-4 flex-column">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      userPayment();
                    }}
                    className="btn btn-secondary w-70 mt-2 text-center "
                  >
                    Просмотреть результат
                  </button>
                </p>
              ) : (
                <div className="d-flex flex-column align-items-center pb-3">
                  {disabled ? (
                    <>
                      <p className="text-black fs-4 text-center fw-bold paddingTop responsive-text">
                        Пожалуйста подождите, пока мы обрабатываем ваш запрос
                      </p>
                      <Loader />
                    </>
                  ) : (
                    <>
                      <p
                        className={`${
                          proverka ? "text-black " : "text-black "
                        } fs-4 fw-bold paddingTop text-center responsive-text`}
                      >
                        {proverka
                          ? "Спасибо оплата прошла успешно"
                          : "Проверьте все поля перед отправкой"}
                      </p>
                      <button
                        className="custom-bg custom-hover-ai border-none text-light text-center p-2 ps-4 pe-3 w-20 diseb"
                        onClick={() => (proverka ? navigate("/hemoai") : null)}
                        type={proverka ? "" : "submit"}
                      >
                        {proverka
                          ? "Просмотреть результат"
                          : "Всё верно. Отправить."}
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : null} */}
      </form>
      <div className="border-bottom border-secondary"></div>
    </div>
  );
};
