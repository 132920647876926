import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthorized: false,
  isLoading: false,
  user: null,
  userData: null,
  responseAi: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      document.cookie =
        "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
      state.isAuthorized = false;
      state.user = null;
    },
    login: (state, { payload }) => {
      state.isAuthorized = true;
      state.user = payload;
    },
    setUserData: (state, { payload }) => {
      state.userData = payload;
    },
    setCookie: (state) => {
      state.isAuthorized = true;
      state.isLoading = false;
    },
    setResponseAi: (state, { payload }) => {
      state.responseAi = payload;
    },
  },
});

export const { setCookie, logout, login, setResponseAi, setUserData } =
  authSlice.actions;

export default authSlice.reducer;
